import React, { useState, useEffect } from 'react';
import { useNylas } from '@nylas/nylas-react';

import NylasLogin from './NylasLogin';
import CalendarApp from './CalendarApp';
import Loader from './Loader';

import Modal from './Workhour/Modal';

const App = ({ nylasAuthenticatable }) => {
  const nylas = useNylas();
  const [email, setEmail] = useState("");
  const [calendarTokens, setCalendarTokens] = useState([]);
  const [calendarToken, setCalendarToken] = useState({});
  const [authInProgress, setAuthInProgress] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!nylas) return;

    // Handle the code that is passed in the query params from Nylas after a successful login from <NylasLogin />
    const params = new URLSearchParams(window.location.search);
    if (params.has('code')) {
      nylas
        .exchangeCodeFromUrlForToken()
        .then((response) => {
          const data = JSON.parse(response);

          const newCalendarToken = data.calendar_token;

          setCalendarTokens([newCalendarToken]);
          setCalendarToken(newCalendarToken);

          setAuthInProgress(false);
        })
        .catch((error) => {
          console.error('An error occurred parsing the response:', error);
        });
    } else {
      setAuthInProgress(false);
    }
  }, [nylas]);

  useEffect(() => {
    if (!nylas) return;
    if (authInProgress) return;

    fetchCalendarTokenIds().then((data) => {
      setCalendarTokens(data);

      let defaultToken = data.find((token) => token.default);
      setCalendarToken(defaultToken || {});
      setEmail(defaultToken?.email || '')

      setIsLoading(false);
    });
  }, [nylas, authInProgress])

  const fetchCalendarTokenIds = () => {
    return fetch('/communication/calendar_tokens')
      .then((response) => response.json())
  }

  const calendarTokensOptions = () => {
    let options = [<option key={0} value="" disabled={true}>Vælg kalender</option>]

    calendarTokens.forEach((token) => {
      options.push(<option key={token.id} value={token.id}>{token.email}</option>)
    })

    return options;
  }

  const displayWorkhours = () => {
    return calendarToken?.default;
  }

  const requireNylasLogin = () => {
    return nylasAuthenticatable && Object.keys(calendarToken).length == 0 || calendarToken?.active == false;
  }

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
          <>
            <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center mb-3">
              <h1 className="mb-3 mb-sm-0">{window.I18n.t('communication.calendar.show.title')}</h1>
                <div className="d-flex flex-column">
                  {calendarTokens.length > 0 && (
                    <div className="d-flex align-items-center gap-2">
                      <i className="d-block ph ph-calendar ph-thin ph-xl" />
                      <select
                        className="form-control"
                        onChange={(e) => {
                          setCalendarToken(calendarTokens.find((token) => token.id === e.target.value))
                        }}
                        value={calendarToken?.id || ""}
                      >
                        {calendarTokensOptions()}
                      </select>
                    </div>
                  )}
                  {displayWorkhours() && (
                    <a
                      href="#"
                      className="text-right"
                      data-toggle="modal"
                      data-target="#workhours-modal"
                    >
                      {window.I18n.t('communication.app.my_workhours')}
                    </a>
                  )}
                </div>
            </div>

            {requireNylasLogin() ? (
              <NylasLogin />
            ) : (
              <div className="app-card">
                {Object.keys(calendarToken).length > 0 && (
                  <CalendarApp
                    calendarToken={calendarToken}
                    email={email}
                  />
                )}
              </div>
            )}

            <Modal email={email} />
          </>
        )}
    </>
  )
}

export default App;
