import React from "react"
import { prioritiseLead } from "../api"

const PrioritiseLead = ({ lead, setLead, setLeads, className }) => {
  const handlePrioritise = (event) => {
    prioritiseLead({ leadId: lead.id })
      .then((response) => response.json())
      .then((data) => {
        setLead(data);

        // only update the lead that was prioritised in setLeads
        setLeads((leads) => {
          return leads.map((lead) => {
            if (lead.id === data.id) {
              return data;
            } else {
              return lead;
            }
          });
        });
      })
  }

  return <i
    onClick={handlePrioritise}
    className={`cursor-pointer ph ph-star ph-lg${lead.prioritised ? " ph-fill text-warning" : ""} ${className}`} />
}

export default PrioritiseLead;
