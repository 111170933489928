import React, { useState, useEffect, useRef } from "react"
import ReactDOM from "react-dom"

import States from "./States"
import Filters from "./Filters"
import LeadsTable from "./LeadsTable"
import NewLeadModal from "./NewModal"
import Sidebar from "./Sidebar"
import Pagination from "./Pagination"

import Loader from "./Loader"

import { countLeads, fetchAvailableAssignees, fetchLeads, fetchLead, fetchCategories, fetchSegments, fetchSources } from "./api"

const url = new URL(window.location.href);

const App = ({ userId }) => {
  const [leads, setLeads] = useState([])
  const [openLead, setOpenLead] = useState(null)
  const [leadsLoading, setLeadsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(parseInt(url.searchParams.get("page")) || 1)
  const [totalPages, setTotalPages] = useState(0)

  const [currentState, setCurrentState] = useState(url.searchParams.get("state") || "all")
  const [currentFilter, setCurrentFilter] = useState({
    filter: url.searchParams.get("filter") || "unassigned",
    assignee_gid: url.searchParams.get("assignee_gid") || "",
    acts_id_null: url.searchParams.get("acts_id_null") || "",
    channel_eq: url.searchParams.get("channel_eq") || "",
    kind_eq: url.searchParams.get("kind_eq") || "",
    category_id: url.searchParams.get("category_id") || "",
    segment_id: url.searchParams.get("segment_id") || "",
    source_eq: url.searchParams.get("source_eq") || "",
    search: url.searchParams.get("search") || "",
  })
  const [currentSorting, setCurrentSorting] = useState({
    sort_by: url.searchParams.get("sort_by") || "due_at",
    sort_order: url.searchParams.get("sort_order") || "asc",
  })

  const [stateCounter, setStateCounter] = useState({})
  const [filterCounter, setFilterCounter] = useState({})

  const [availableAssignees, setAvailableAssignees] = useState([])
  const [categories, setCategories] = useState([])
  const [segments, setSegments] = useState([])
  const [sources, setSources] = useState([])

  const newLeadRef = useRef(null);

  // Fetch lead and open sidebar if lead_id query param is present when component mounts
  useEffect(() => {
    const leadId = url.searchParams.get("lead_id");

    if (leadId && leadId != "" && leadId != openLead?.id) {
      fetchLead({ leadId: leadId })
        .then((response) => response.json())
        .then((data) => {
          setOpenLead(data);
        })
    }
  }, [])

  // Fetch available assignees when component mounts
  useEffect(() => {
    fetchAvailableAssignees()
      .then((response) => response.json())
      .then((data) => {
        setAvailableAssignees(data)
      })
  }, [])

  // Fetch available categories when component mounts
  useEffect(() => {
    fetchCategories()
      .then((response) => response.json())
      .then((data) => {
        setCategories(data);
      })
  }, [])

  // Fetch available segments when component mounts
  useEffect(() => {
    fetchSegments()
      .then((response) => response.json())
      .then((data) => {
        setSegments(data);
      })
  }, [])

  useEffect(() => {
    fetchSources()
      .then((response) => response.json())
      .then((data) => {
        setSources(data);
      })
  }, [])

  // Set lead_id query param in URL on openLead change
  useEffect(() => {
    if (openLead) {
      url.searchParams.set("lead_id", openLead.id);
    } else {
      url.searchParams.delete("lead_id");
    }

    window.history.pushState({}, "", url);
  }, [openLead])

  // Count leads on state change
  useEffect(() => {
    countLeads({ userId: userId, state: currentState })
      .then((response) => response.json())
      .then((data) => {
        setStateCounter(data.state_counter);
        setFilterCounter(data.filter_counter);
      })
  }, [currentState])

  // Fetch leads on state/filter/page change
  useEffect(() => {
    setLeadsLoading(true);

    fetchLeads({ userId: userId, state: currentState, filter: currentFilter, page: currentPage, sorting: currentSorting })
      .then((response) => response.json())
      .then((data) => {
        setLeads(data.data)
        setTotalPages(data.total_pages)

        if (currentPage > data.total_pages) {
          setCurrentPage(1);
        }

        setLeadsLoading(false)
      })
  }, [currentState, currentFilter, currentPage, currentSorting])

  // Set query params in URL on state/filter/page change
  useEffect(() => {
    url.searchParams.set("user_id", userId);
    url.searchParams.set("page", currentPage);
    url.searchParams.set("state", currentState);
    url.searchParams.set("filter", currentFilter.filter);
    url.searchParams.set("assignee_gid", currentFilter.assignee_gid);
    url.searchParams.set("acts_id_null", currentFilter.acts_id_null);
    url.searchParams.set("channel_eq", currentFilter.channel_eq);
    url.searchParams.set("kind_eq", currentFilter.kind_eq);
    url.searchParams.set("category_id", currentFilter.category_id);
    url.searchParams.set("segment_id", currentFilter.segment_id);
    url.searchParams.set("source_eq", currentFilter.source_eq);
    url.searchParams.set("search", currentFilter.search);
    url.searchParams.set("sort_by", currentSorting.sort_by);
    url.searchParams.set("sort_order", currentSorting.sort_order);

    window.history.pushState({}, "", url);
  }, [currentPage, currentState, currentFilter, currentSorting])

  const handleLeadCreated = (lead) => {
    // Close modal
    newLeadRef.current.click();

    // prepend lead to leads
    setLeads((leads) => [lead, ...leads]);
  }

  return (
    <>
      <div className="h-100">
        <States
          currentState={currentState}
          setCurrentState={setCurrentState}
          counter={stateCounter} />
        <Filters
          currentFilter={currentFilter}
          setCurrentFilter={setCurrentFilter}
          counter={filterCounter}
          availableAssignees={availableAssignees}
          availableCategories={categories}
          availableSegments={segments}
          availableSources={sources}
        />

        <div className="d-flex justify-content-end mt-2">
          <button
            type="button"
            className="btn btn-primary-dark"
            data-toggle="modal"
            data-target="#new-lead-modal"
            ref={newLeadRef}
          >
            {I18n.t('leads.new_modal.title')}
          </button>
        </div>

        {leadsLoading ?
          <Loader />
          :
          <div className={`bg-white rounded box-shadow border position-relative mt-2`}>
            <LeadsTable
              leads={leads}
              setLeads={setLeads}
              setOpenLead={setOpenLead}
              sorting={currentSorting}
              setSorting={setCurrentSorting}
            />
          </div>
        }

        {!leadsLoading &&
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        }
      </div>

      <NewLeadModal callback={handleLeadCreated} />
      <Sidebar
        lead={openLead}
        setLead={setOpenLead}
        setLeads={setLeads} />
    </>
  )
}

export default App;

document.addEventListener("DOMContentLoaded", () => {
  const domEls = document.querySelectorAll(".leads-app");

  domEls.forEach((domEl) => {
    const userId = domEl.dataset.userId || '';

    ReactDOM.render(
      <App userId={userId} />,
      domEl
    );
  });
});
