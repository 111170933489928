import React from 'react';

const Conferencing = ({ videoProvider, videoUrl }) => {
  if (!videoProvider || !videoUrl) return (<></>);

  let icon = "";

  switch (videoProvider) {
    case "Google Meet":
      icon = "ph-google-logo";
      break;
    case "Microsoft Teams":
      icon = "ph-microsoft-teams-logo";
      break;
    default:
      icon = "ph-video-camera";
  }

  return (
    <div className="d-flex justify-content-between">
      <i className={`ph ${icon} ph-xl d-block mt-1 mr-3`} />
      <div className="flex-grow-1">
        <a
          href={videoUrl}
          target="_blank"
          className="btn btn-primary btn-sm d-block"
        >
          Join with {videoProvider}
        </a>
      </div>
    </div>
  )
}

export default Conferencing;
