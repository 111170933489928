import React from "react"

import ListItem from "./ListItem"
import { destroyAct } from "./api"

const List = ({ acts, setActs }) => {
  const handleDestroyAct = ({ actId }) => {
    destroyAct({ actId })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          const newActs = acts.filter((act) => act.id != actId)
          setActs(newActs)

          CustomToastr.success(window.I18n.t("acts.destroy.success"))
        } else {
          alert(data.error)
        }
      })
  }

  if (acts.length == 0) return <small className="text-muted">{window.I18n.t("acts.list.no_acts")}</small>

  return (
    <div className="log">
      {acts.map((act) => (
        <ListItem key={act.id} act={act} destroyAct={handleDestroyAct} />
      ))}
    </div>
  )
}

export default List
