import React, { useState, useEffect } from "react"

import { createAct, fetchActReasons } from "./api"

const NewModal = ({ type, subjectType, subjectId, callback, modalId = "new-act-modal"}) => {
  const [actReasons, setActReasons] = useState([])

  const [actReasonId, setActReasonId] = useState("")
  const [description, setDescription] = useState("")
  const [setCustomDueAt, setSetCustomDueAt] = useState(false)
  const [dueAt, setDueAt] = useState("")
  const [publicToSuppliers, setPublicToSuppliers] = useState(true)

  const [createActLoading, setCreateActLoading] = useState(false)

  useEffect(() => {
    fetchActReasons()
      .then((response) => response.json())
      .then((data) => {
        setActReasons(data)
      })
  }, [])

  const resetForm = () => {
    setActReasonId("")
    setDescription("")
    setSetCustomDueAt(false)
    setDueAt("")
    setPublicToSuppliers(true)
  }

  const handleActReasonChange = (event) => {
    const actReason = actReasons.find((actReason) => actReason.id == event.target.value)

    if (!actReason) return

    setActReasonId(actReason.id)
    setDueAt(moment().add(actReason.due_at_offset, "minutes").format("YYYY-MM-DDTHH:mm"))
  }

  const handleCreateAct = () => {
    setCreateActLoading(true)

    createAct({
      type: type,
      subjectType: subjectType,
      subjectId: subjectId,
      actReasonId: actReasonId,
      description: description,
      dueAt: dueAt,
      publicToSuppliers: publicToSuppliers,
    })
      .then((response) => response.json())
      .then((data) => {
        callback(data)
        setCreateActLoading(false)
        resetForm()
      })
  }

  const actReasonOptions = () => {
    let options = []

    options.push(<option key="default" value="">{I18n.t("shared.select")}</option>)

    actReasons.forEach((actReason) => {
      options.push(<option key={actReason.id} value={actReason.id}>{actReason.title}</option>)
    })

    return options
  }

  return (
    <div className="modal fade" key={modalId} id={modalId}>
      <div className="modal-dialog modal-dialog-centered position-relative" role="document">
        <div className="modal-content modal-content-simple overflow-auto">
          <div className="modal-header px-4">
            <h5 className="modal-title" id="exampleModalLabel">{I18n.t("acts.new_modal.title")}</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body p-4">
            <div className="form-group">
              <label
                htmlFor="act_reason_id"
                className="small"
              >
                {window.I18n.t("helpers.label.act.act_reason_id")}
              </label>
              <select
                className="form-control custom-select"
                id="act_reason_id"
                value={actReasonId}
                onChange={handleActReasonChange}
              >
                {actReasonOptions()}
              </select>
            </div>

            <div className="form-group">
              <label
                htmlFor="description"
                className="small"
              >
                {window.I18n.t("helpers.label.act.description")}
              </label>
              <textarea
                className="form-control"
                id="description"
                value={description}
                onChange={(event) => setDescription(event.target.value)} />
            </div>

            <div className="form-group">
              <span>{window.I18n.t("acts.new_modal.next_follow_up")} </span>
              <span className="font-weight-bold">
                {dueAt == "" ? (
                  <>{window.I18n.t("acts.new_modal.select_act_reason")}</>
                ) : (
                  <>{moment(dueAt).format("DD.MM.YYYY - HH:mm")}</>
                )}
              </span>
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="custom_due_at"
                  checked={setCustomDueAt}
                  onChange={(event) => setSetCustomDueAt(event.target.checked)} />
                <label
                  className="custom-control-label"
                  htmlFor="custom_due_at"
                >
                  {window.I18n.t("acts.new_modal.set_custom_due_at")}
                </label>
              </div>

              {setCustomDueAt && (
                <div className="form-group">
                  <input
                    type="datetime-local"
                    className="form-control"
                    value={dueAt}
                    onChange={(event) => setDueAt(event.target.value)} />
                </div>
              )}
            </div>

            <div className="form-group mb-0">
              <button
                className="btn btn-primary btn-block btn-sm"
                onClick={() => handleCreateAct()}
                disabled={createActLoading}
              >
                {window.I18n.t('shared.save')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewModal;
