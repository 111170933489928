import React, { useState, useEffect } from 'react';

import EditEvent from './EditEvent';
import ShowEvent from './ShowEvent';
import Reschedule from './Reschedule';

const Modal = ({ event, setEvent, setEvents, calendarToken, email }) => {
  const [editable, setEditable] = useState(false);
  const [reschedule, setReschedule] = useState(false);

  useEffect(() => {
    // always reset editable to false when event changes
    setEditable(false);
    setReschedule(false);

    $('.edit-modal [data-toggle="tooltip"]').bstooltip({ 'html': true });
  }, [event]);

  const handleDeleteEvent = () => {
    const confirmed = window.confirm(window.I18n.t('communication.event.confirm_delete'));
    if(!confirmed) return;

    fetch(`/communication/calendar_tokens/${calendarToken.id}/events/${event.id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
      }
    }).then(() => {
      setEvent(null);
      setEvents((events) => events.filter((e) => e.eventId !== event.eventId));
    }).catch((error) => console.error(error));
  };

  const handleRescheduleEvent = (updatedEvent) => {
    setEvent(updatedEvent);
    setEvents((events) => events.map((event) => event.id == updatedEvent.id ? updatedEvent : event));
  };

  if (!event) return <></>;

  return (
    <div
      className='edit-modal position-fixed'
      onClick={() => setEvent(null)}
    >
      <div
        className='card shadow-lg overflow-hidden'
        style={{ width: '400px' }}
        onClick={(e) => e.stopPropagation()}
      >
        <div className='card-header d-flex justify-content-end'>
          <div className='d-flex justify-content-between align-items-center gap-2'>
            {event.owner === calendarToken.email && (
              <>
                { event.participants.length !== 0 &&
                  <i
                    className={`ph ${reschedule ? 'ph-arrow-counter-clockwise' : 'ph-calendar-blank'} ph-thin ph-xl cursor-pointer`}
                    data-toggle='tooltip'
                    data-placement='top'
                    title='Reschedule'
                    onClick={() => {
                      setEditable(false);
                      setReschedule(!reschedule);
                    }}
                  />
                }
                <i
                  className={`ph ${editable ? 'ph-arrow-counter-clockwise' : 'ph-pencil'} ph-thin ph-xl cursor-pointer`}
                  data-toggle='tooltip'
                  data-placement='top'
                  title='Edit'
                  onClick={() => {
                    setReschedule(false);
                    setEditable(!editable);
                  }}
                />
                <i
                  className='ph ph-trash ph-thin ph-xl cursor-pointer'
                  data-toggle='tooltip'
                  data-placement='top'
                  title='Delete'
                  onClick={handleDeleteEvent}
                />
              </>
            )}
            <i className='ph ph-x ph-thin ph-xl cursor-pointer' onClick={() => setEvent(null)} />
          </div>
        </div>
        <div className='card-body pt-2 overflow-auto'>
          {!editable && !reschedule && <ShowEvent event={event} />}
          {editable &&
            <EditEvent
              event={event}
              setEvent={setEvent}
              setEvents={setEvents}
              setEditable={setEditable}
              calendarToken={calendarToken} />}
          {reschedule &&
            <Reschedule
              event={event}
              callback={handleRescheduleEvent} />}
        </div>
      </div>
    </div>
  );
};

export default Modal;
