$(document).ready(function() {
  $('.load-and-redirect').click(loadAndRedirect)
});

function loadAndRedirect(e) {
  e.preventDefault();

  let parent = $($(this).data('parent') || '.fixed-loader-container');
  let message = $(this).data('message');
  let duration = $(this).data('duration') || 1000
  let href = $(this).attr('href');

  parent.prepend(`
    <div class="loader-overlay">
      <div class="loader">&nbsp;</div>
      <div class="loader-explain">${message}</div>
    </div>
  `);

  setTimeout(function() {
    window.location.href = href;
  }, duration)
}
