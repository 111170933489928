document.addEventListener('DOMContentLoaded', function() {
  var numberInputs = $('input[type=text].formatted-number');
  if (numberInputs !== null) {
    numberInputs.each(function(index) {
      new Cleave(this, {
        numeralThousandsGroupStyle: 'thousand',
        numericOnly: true,
        numeral: true,
        numeralDecimalMark: I18n.t('number.format.separator'),
        delimiter: I18n.t('number.format.delimiter'),
        numeralDecimalScale: 2
      });
    })
  }

  window.initiateTelInputs();

  $('.cleaved-date').each(function (index) {
    window.initiateDateInput(this);
  });

  $('.cleaved-registration-number').each(function (index) {
    window.initiateRegistrationNumberInput(this);
  });

  $('.cleaved-account-number').each(function (index) {
    window.initiateAccountNumberInput(this);
  });

  $('.cleaved-time').each(function (index) {
    window.initiateTimeInput(this);
  });

  $('.cleaved-number-unformatted').each(function (index) {
    window.initiateUnformattedNumberInput(this);
  });

  $('.cleaved-positive-number').each(function() {
    window.initiatePositiveNumberInput(this);
  });

  setTimeout(function () {
    $('.force-snake-case').each(function (index) {
      window.initiateSnakeCaseFormatter(this);
    });
  }, 1000);

  initiateSelect2()

  $("select[id$='_country_code']").each(function () {
    window.sortSelectOptionsAlphabetically(this);
  });
});

window.getFullPhone = function (dom) {
  if (dom instanceof jQuery) dom = dom.get(0);
  if (!dom) return '';
  var iti = window.intlTelInputGlobals.getInstance(dom);
  return iti ? iti.getNumber() : '';
}

window.initiateTelInput = function (dom) {
  var iti = window.intlTelInputGlobals.getInstance(dom);

  if (iti) return

  intlTelInput(dom, {
    hiddenInput: 'full_phone',
    preferredCountries: [window.currentTenant?.country_code || 'DK'],
    utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.5/js/utils.min.js',
    separateDialCode: true,
    autoPlaceholder: 'off',
  });

  $(dom.form).on('submit', function (e) {
    // JP: Implemented .parent() here to make it work for nested attributes in quick creation
    $(dom).parent().find('input[type=tel]').each(function () {
      var iti = window.intlTelInputGlobals.getInstance(dom);
      var full_phone = iti.getNumber();
      $(dom).next().val(full_phone);
    });
  });
}

window.initiateTelInputs = function () {
  $('input[type=tel]').each(function () {
    window.initiateTelInput(this);
  });
}

String.prototype.toSnakeCase = function () {
  return this.toLowerCase().replaceAll(/[^\w\s]/gi, '').replaceAll(' ', '_');
};

window.initiateSnakeCaseFormatter = function (dom) {
  $(dom).on("input change paste", function () {
    this.value = this.value.toSnakeCase();
  });
}

window.initiateCleaveFormatters = function(el) {
  el.find('.cleaved-number').each(function () {
    window.initiateNumberInput(this);
  });

  el.find('.cleaved-positive-number').each(function () {
    window.initiatePositiveNumberInput(this);
  });

  el.find('.cleaved-number-unformatted').each(function () {
    window.initiateUnformattedNumberInput(this);
  });

  el.find('.cleaved-date').each(function () {
    window.initiateDateInput(this);
  });

  el.find('.cleaved-time').each(function () {
    window.initiateTimeInput(this);
  });

  el.find('.ssn-validator').each(function () {
    window.initiateSSNFormatter(this);
  });

  el.find('.company-number-validator').each(function () {
    window.initiateCompanyNumberFormatter(this);
  });

  el.find('.cleaved-registration-number').each(function () {
    window.initiateRegistrationNumberInput(this);
  });

  el.find('.cleaved-account-number').each(function () {
    window.initiateAccountNumberInput(this);
  });

  el.find('input[type=tel]').each(function () {
    window.initiateTelInput(this);
  });
}

window.initiateNumberInput = function (dom) {
  return new Cleave(dom, {
    numeralThousandsGroupStyle: 'thousand',
    numericOnly: true,
    numeral: true,
    numeralDecimalMark: I18n.t('number.format.separator'),
    delimiter: I18n.t('number.format.delimiter'),
    numeralDecimalScale: 2,
    onValueChanged: function (e) {
      $(dom).trigger('input')
    }
  });
}

window.initiatePositiveNumberInput = function (dom) {
  return new Cleave(dom, {
    numeralThousandsGroupStyle: 'thousand',
    numericOnly: true,
    numeral: true,
    numeralDecimalMark: I18n.t('number.format.separator'),
    delimiter: I18n.t('number.format.delimiter'),
    numeralDecimalScale: 2,
    numeralPositiveOnly: true,
    onValueChanged: function (e) {
      $(dom).trigger('input')
    }
  });
}

/* Tenant specific SSN formatters */

document.addEventListener('DOMContentLoaded', () => {
  var ssnInputs = $('.ssn-validator');
  ssnInputs.each(function (index) {
    window.initiateSSNFormatter(this);
  });
})

window.initiateSSNFormatter = function(dom) {
  switch (window.currentTenant.country_code) {
    case 'DK':
      window.initiateSSNFormatterDK(dom);
      break;
    case 'BR':
      window.initiateSSNFormatterBR(dom);
      break;
    case 'NO':
      window.initiateSSNFormatterNO(dom);
      break;
    case 'FI':
      window.initiateSSNFormatterFI(dom);
      break;
  }
}

window.initiateSSNFormatterDK = function(dom) {
  new Cleave(dom, {
    numericOnly: true,
    blocks: [6, 4],
    delimiter: '-',
    onValueChanged: window.cleaveValidator
  });
}

window.cleaveValidator = function (e) {
  var isInvalid = (e.target.rawValue.length != this.properties.maxLength) && e.target.rawValue.length > 0;

  if (isInvalid) {
    $(this.element).addClass('is-invalid');
  } else {
    $(this.element).removeClass('is-invalid');
  }
}

window.initiateSSNFormatterBR = function(dom) {
  new Cleave(dom, {
    numericOnly: true,
    blocks: [3, 3, 3, 2],
    delimiters: ['.', '.', '-'],
  });
}

window.initiateSSNFormatterNO = function(dom) {
  new Cleave(dom, {
    numericOnly: true,
    blocks: [11],
  });
}

window.initiateSSNFormatterFI = function(dom) {
  new Cleave(dom, {
    numericOnly: false,
    blocks: [11],
  });
}

/* Tenant specific company number formatters */

var companyNumberInputs = $('.company-number-validator');
companyNumberInputs.each(function (index) {
  window.initiateCompanyNumberFormatter(this);
});

window.initiateCompanyNumberFormatter = function (dom) {
  switch (window.currentTenant.country_code) {
    case 'DK':
      window.initiateCompanyNumberFormatterDK(dom);
      break;
    case 'BR':
      window.initiateCompanyNumberFormatterBR(dom);
      break;
    case 'NO':
      window.initiateCompanyNumberFormatterNO(dom);
      break;
  }
}

window.initiateCompanyNumberFormatterDK = function (dom) {
  new Cleave(dom, {
    numericOnly: true,
    blocks: [8]
  });
}

window.initiateCompanyNumberFormatterBR = function (dom) {
  new Cleave(dom, {
    numericOnly: true,
    blocks: [2, 3, 3, 4, 2],
    delimiters: ['.', '.', '/', '-'],
  });
}

window.initiateCompanyNumberFormatterNO = function (dom) {
  new Cleave(dom, {
    numericOnly: true,
    blocks: [3, 3, 3],
    delimiters: [' ', ' '],
  });
}

window.initiateDateInput = function (dom) {
  let cleave = new Cleave(dom, {
    date: true,
    delimiters: ['/', '-'],
    datePattern: ['d', 'm', 'Y'],
  });
  $(cleave.element).attr('placeholder', 'dd/mm-yyyy');
}

window.initiateRegistrationNumberInput = function (dom) {
  dom.setAttribute("maxlength", 4);
  $(dom).on('input', function() {
    this.value = this.value.replace(/[^0-9]/gi, "");
  });
}

window.initiateUnformattedNumberInput = function(dom) {
  return new Cleave(dom, {
    numeralThousandsGroupStyle: 'none',
    numericOnly: true,
    numeral: true,
  });
}

window.initiateAccountNumberInput = function (dom) {
  dom.setAttribute("maxlength", 10);
  $(dom).on('input', function () {
    this.value = this.value.replace(/[^0-9]/gi, "");
  });
}

window.initiateTimeInput = function (dom) {
  let cleave = new Cleave(dom, { time: true, timePattern: ['h', 'm'], onValueChanged: window.cleaveValidator });
  $(cleave.element).attr('placeholder', 'hh:mm');
}

window.uncleaveNumber = function(str) {
  var el = $(`<input></input>`).val(str);
  var cleave = window.initiateNumberInput(el);
  return cleave.getRawValue();
}

window.cleaveNumber = function (double) {
  return I18n.l('number', Math.round(double * 100) / 100);
}

window.initiateSelect2 = function() {
  $('.select-searchable').each(function() {
    let parentId = $(this).closest('.modal').attr('id') || null;

    let select2Options = {
      theme: 'bootstrap',
      placeholder: $(this).data('placeholder'),
      width: '100%',
    };
    if (parentId) select2Options.dropdownParent = `#${parentId}`;

    $(this).select2(select2Options);
  });
};

// Focus on search field when opening Select2. Bug caused by mismatch between Select2 and jQuery.
// https://stackoverflow.com/a/73543335
$(document).on('select2:open', function (e) {
  document.querySelector(`[aria-controls="select2-${e.target.id}-results"]`).focus();
});

window.sortSelectOptionsAlphabetically = function (selectEl) {
  var options = $(selectEl).find('option');
  var selected = $(selectEl).find('option:selected');

  var sortedOptions = options.sort(function (a, b) {
    if (a.text.startsWith('Å')) return 1;
    if (b.text.startsWith('Å')) return -1;
    if (a.text < b.text) return -1;
    if (a.text > b.text) return 1;
    return 0;
  })
  $(selectEl).empty().append(sortedOptions);
  $(selectEl).val(selected.val());
}
