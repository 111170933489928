$(document).ready(function () {
  var searchInputEl = $('#search-input');
  let searchTime = 0;

  searchInputEl.on('input', function () {
    clearTimeout(searchTime);
    let searchInputEl = $(this)
    let searchValue = searchInputEl.val();
  
    let suggestionsContainerEl = searchInputEl.parent().find('.search-result-container');
    let suggestionsUlEl = suggestionsContainerEl.find('.suggestions');
  
    suggestionsUlEl.empty();
  
    var searchingSearchResultHtml = $('#searching-search-result-template').html();
    var searchingSearchResulEl = $(searchingSearchResultHtml);
    suggestionsUlEl.append(searchingSearchResulEl);
  
    suggestionsContainerEl.addClass('show');
  
    if (searchValue.length < 3) {
      suggestionsContainerEl.removeClass('show');
      return
    }
  
    searchTime = setTimeout(function () { submitSearchString(searchInputEl, searchValue); }, 1000);
  });
  
  searchInputEl.focusout(function () {
    let searchInputEl = $(this)
    let searchValue = searchInputEl.val();
  
    let suggestionsContainerEl = searchInputEl.parent().find('.search-result-container');
    let suggestionsUlEl = suggestionsContainerEl.find('.suggestions');
  
    setTimeout(() => { suggestionsContainerEl.removeClass('show'); }, 150);
  });
  
  searchInputEl.focusin(function () {
    let searchInputEl = $(this)
    let searchValue = searchInputEl.val();
  
    let suggestionsContainerEl = searchInputEl.parent().find('.search-result-container');
    let suggestionsUlEl = suggestionsContainerEl.find('.suggestions');
  
    if (searchValue.length > 1) {
      suggestionsContainerEl.addClass('show');
    }
  });

  searchInputEl.keyup(function (event) {
    if (event.keyCode === 38 || event.keyCode === 40 || event.keyCode === 13) {
      handleSearchResultSuggestionSelect(event.keyCode, $(this));
    }
  });
});

function submitSearchString(el, search) {
  url_encoded_search = encodeURIComponent(search);
  $.ajax({
    type: 'GET',
    url: '/search?search=' + url_encoded_search,
    success: function (data) {
      var suggestionsContainerEl = el.parent().find('.search-result-container');
      var suggestionsUlEl = suggestionsContainerEl.find('.suggestions');

      suggestionsUlEl.empty();

      if (data.length > 0) {
        data.forEach((result) => {
          var newSearchResultHtml = $('#search-result-template').html();
          var newSearchResultEl = $(newSearchResultHtml);

          var newSearchResultIconHtml = getSearchResultIconByType(result.type);
          var newSearchResultIconEl = $(newSearchResultIconHtml);

          newSearchResultEl.find('.search-result-type').html(newSearchResultIconEl);
          newSearchResultEl.find('.search-result-title').html(result.title);
          newSearchResultEl.find('.search-result-description').html(result.type + ": " + (result.description || ''));
          newSearchResultEl.find('.search-result-column').html(result.match_column);
          newSearchResultEl.find('.search-result-percentage').html((result.match_percentage * 100).toFixed(2) + "%");
          newSearchResultEl.find('.search-result-info').attr('href', result.url);
          suggestionsUlEl.append(newSearchResultEl);
        })
      } else {
        var emptySearchResultHtml = $('#empty-search-result-template').html();
        var emptySearchResulEl = $(emptySearchResultHtml);
        suggestionsUlEl.append(emptySearchResulEl);
      }
    }
  });
}

function getSearchResultIconByType(type) {
  let text = '';
  switch (type) {
    case 'CaseFile':
      text = 'Sag';
      break;
    case 'Document':
    case 'CaseFileDocument':
      text = 'Dokument';
      break;
    case 'User':
      text = 'Bruger';
      break;
    case 'Advisor':
      text = 'Rådgiver';
      break;
    case 'Part':
      text = 'Part';
      break;
    case 'PartOption':
      text = 'Part option';
      break;
    case 'Lead':
      text = 'Lead';
      break;
    default:
      text = 'Resultat';
      break;
  }
  return `<span class="badge badge-light px-2 border">${text}</span>`;
}

function handleSearchResultSuggestionSelect(keyCode, element) {
  let searchResultContainerEl = element.parent().find('.search-result-container');
  let suggestionsEl = searchResultContainerEl.find('.suggestions');
  let currentPosition = suggestionsEl.find('.selected');

  if (keyCode === 38) {
    // go up
    if (currentPosition.length && currentPosition.prev().length) {
      // item selected and previous item is present
      currentPosition.removeClass('selected');
      currentPosition.prev().addClass('selected');
    } else {
      // previous item is not present - select last item
      currentPosition.removeClass('selected');
      suggestionsEl.find('.suggestion-item').last().addClass('selected');
    }
  } else if (keyCode === 40) {
    // go down
    if (currentPosition.length && currentPosition.next().length) {
      // item selected and next item is present
      currentPosition.removeClass('selected');
      currentPosition.next().addClass('selected');
    } else {
      // next item is not present - select first item
      currentPosition.removeClass('selected');
      suggestionsEl.find('.suggestion-item').first().addClass('selected');
    }
  } else if (keyCode === 13 && currentPosition.length) {
    currentPosition.click();
  }
}
