import React, { useState, useEffect } from "react"

const Pagination = ({totalPages, currentPage, setCurrentPage}) => {
  const [shownPages, setShownPages] = useState([])

  useEffect(() => {
    setShownPages(updateShownPages())
  }, [currentPage, totalPages])

  const updateShownPages = () => {
    if (totalPages < 6) {
      return Array.from(Array(totalPages).keys()); // Show all pages
    } else {
      if (currentPage < 4) {
        return range(5); // Show 1-5
      } else if (currentPage > totalPages - 3) {
        return range(5, totalPages - 4); // Show the last 5 pages
      } else {
        return range(5, currentPage - 2); // Show the 2 pages before and after the current page
      }
    }
  }

  function range(size, startAt = 1) {
    return [...Array(size).keys()].map(i => i - 1 + startAt);
  }

  return (
    <>
      { totalPages > 0 && (
        <div className="d-flex justify-content-center mt-2">
          <ul className="pagination lh-custom-pagination">
            <>
              <li className={`page-item${currentPage < 3 || totalPages < 5 ? " cursor-not-allowed disabled" : " cursor-pointer"}`}>
                <a className="page-link" onClick={() => setCurrentPage(1)}>
                  <i className="ph ph-caret-double-left ph-thin"></i>
                </a>
              </li>
              <li className={`page-item${currentPage == 1 || totalPages < 2 ? " cursor-not-allowed disabled" : " cursor-pointer"}`}>
                <a className="page-link" onClick={() => setCurrentPage(currentPage - 1)}>
                  <i className="ph ph-caret-left ph-thin"></i>
                </a>
              </li>

              {totalPages > 5 && currentPage > 3 &&
                <li className={'page-item cursor-pointer'}>
                  <a className="page-link" onClick={() => setCurrentPage(Math.floor(parseInt(currentPage / 2)))}>...</a>
                </li>
              }

              {shownPages.map((page) => (
                <li className={`page-item cursor-pointer${page + 1 === currentPage ? " active" : ""}`} key={page}>
                  <a className="page-link" onClick={() => setCurrentPage(page + 1)}>{page + 1}</a>
                </li>
              ))}

              {totalPages > 6 && currentPage < totalPages - 4 &&
                <li className={'page-item cursor-pointer'}>
                  <a className="page-link" onClick={() => setCurrentPage(Math.min(Math.round(currentPage + parseInt((totalPages - currentPage) / 2)), totalPages))}>...</a>
                </li>
              }

              <li className={`page-item${currentPage == totalPages ? " cursor-not-allowed disabled" : " cursor-pointer"}`}>
                <a className="page-link" onClick={() => setCurrentPage(currentPage + 1)}>
                  <i className="ph ph-caret-right ph-thin"></i>
                </a>
              </li>
              <li className={`page-item${currentPage == totalPages ? " cursor-not-allowed disabled" : " cursor-pointer"}`}>
                <a className="page-link" onClick={() => setCurrentPage(totalPages)}>
                  <i className="ph ph-caret-double-right ph-thin"></i>
                </a>
              </li>
            </>
          </ul>
        </div>
      )}
    </>
  )
}

export default Pagination
