import React, { useState, useEffect } from "react";

import Fields from "./Fields";

import { updateLead } from "./api";

const EditModal = ({ lead, callback }) => {
  const initialFormState = {
    customer_name: "",
    customer_email: "",
    customer_phone: "",
    customer_ssn: "",
    description: "",
    due_at: moment().add(1, 'day').format('YYYY-MM-DDTHH:mm'),
    kind: "",
    channel: "",
    category_id: "",
    segment_id: "",
    note: "",
  };
  const [formData, setFormData] = useState(initialFormState);

  const [updateLeadLoading, setUpdateLeadLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (!lead) return;

    setFormData({
      assignee_gid: lead.assignee_gid || "",
      customer_name: lead.customer_info.name || "",
      customer_email: lead.customer_info.email || "",
      customer_phone: lead.customer_info.phone || "",
      customer_ssn: lead.customer_info.ssn || "",
      description: lead.description || "",
      due_at: lead.due_at ? moment(lead.due_at).format("YYYY-MM-DDTHH:mm:ss") : "",
      kind: lead.kind || "",
      category_id: lead.category?.id || "",
      segment_id: lead.segment?.id || "",
    })
  }, [lead]);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    let newValue = value;
    // For checkboxes, use the "checked" property instead of "value"
    if(type === "checkbox") newValue = checked;
    // Channel needs to be passed to backend as integer instead of string
    if(name === "channel") newValue = parseInt(value);

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setUpdateLeadLoading(true);

    updateLead({ leadId: lead.id, atrs: formData })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          setErrorMessage(data.error);
        } else {
          callback(data);
        }

        setUpdateLeadLoading(false);
      });
  }

  return (
    <div className="modal fade" key="edit-lead-modal" id="edit-lead-modal">
      <div className="modal-dialog modal-lg modal-dialog-centered position-relative" role="document">
        <div className="modal-content modal-content-simple overflow-auto">
          <div className="modal-header px-4">
            <h5 className="modal-title" id="exampleModalLabel">{I18n.t("leads.edit_modal.title")}</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body p-4">
            <form onSubmit={handleSubmit}>
              <Fields
                formName="edit"
                formData={formData}
                handleChange={handleChange} />

              <div className="form-group d-flex justify-content-end">
                <button className="btn btn-primary" disabled={updateLeadLoading}>
                  {updateLeadLoading ? (
                    <span>
                      <div className="spin spin-xxxs spin-light mr-2" />
                      {I18n.t("shared.saving")}
                    </span>
                  ) : (
                    <>{I18n.t("shared.save")}</>
                  )}
                </button>
              </div>

              {errorMessage !== "" && (
                <div className="alert alert-danger">
                  {errorMessage}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditModal;
