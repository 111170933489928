import React, { useState, useEffect, useRef } from "react";
import { hoursMinutesSecondsInUTC, hoursMinutesSecondsInLocal } from "../Helpers/TimeFormatters";

const Field = ({ email, day, defaultStartValue, defaultEndValue, _currentWorkhour }) => {
  const [currentWorkhour, setCurrentWorkhour] = useState(_currentWorkhour);

  const dayStartRef = useRef();
  const dayEndRef = useRef();

  useEffect(() => {
    setCurrentWorkhour(_currentWorkhour);
  }, [_currentWorkhour]);

  const timeslots = () => {
    const timeslots = [];

    for (let i = 0; i < 24; i++) {
      var hour = i < 10 ? `0${i}` : i;
      timeslots.push(`${hour}:00:00`);
      timeslots.push(`${hour}:15:00`);
      timeslots.push(`${hour}:30:00`);
      timeslots.push(`${hour}:45:00`);
    }

    return timeslots;
  }

  const renderTimeslotOptions = () => {
    return timeslots().map((timeslot, index) => {
      return <option key={index} value={timeslot}>{timeslot.substring(0, timeslot.length-3)}</option>
    })
  }

  const updateWorkhour = () => {
    let dayStartUTC = hoursMinutesSecondsInUTC(dayStartRef.current.value);
    let dayEndUTC = hoursMinutesSecondsInUTC(dayEndRef.current.value);

    let data = {
      workhour: {
        ...currentWorkhour,
        day_start: dayStartUTC,
        day_end: dayEndUTC,
      }
    }

    return fetch(`/communication/workhours/${currentWorkhour.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify(data)
    })
  }

  const createWorkhour = () => {
    let dayStartUTC = hoursMinutesSecondsInUTC(dayStartRef.current.value);
    let dayEndUTC = hoursMinutesSecondsInUTC(dayEndRef.current.value);

    let data = {
      workhour: {
        email: email,
        day: day,
        day_start: dayStartUTC,
        day_end: dayEndUTC,
      }
    }

    return fetch(`/communication/workhours/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify(data)
    })
  }

  const handleChangeWorkhour = () => {
    const request = currentWorkhour?.id ? updateWorkhour() : createWorkhour();

    request
      .then((response) => response.json())
      .then((data) => {
        setCurrentWorkhour(data);
      });
  }

  const dayMapping = {
    0: 'monday',
    1: 'tuesday',
    2: 'wednesday',
    3: 'thursday',
    4: 'friday',
    5: 'saturday',
    6: 'sunday',
  }

  return (
    <div className="col-12">
      <label>{I18n.t(`communication.workhour.fields.${dayMapping[day]}`)}</label>
      <div className="row row-spaced-2">
        <div className="col-6 d-flex align-items-center gap-2">
          <label className="mb-0">{I18n.t('communication.workhour.fields.start')}</label>
          <select
            className="form-control"
            value={hoursMinutesSecondsInLocal(currentWorkhour?.day_start || defaultStartValue)}
            onChange={handleChangeWorkhour}
            ref={dayStartRef}
          >
            {renderTimeslotOptions()}
          </select>
        </div>
        <div className="col-6 d-flex align-items-center gap-2">
          <label className="mb-0">{I18n.t('communication.workhour.fields.end')}</label>
          <select
            className="form-control"
            value={hoursMinutesSecondsInLocal(currentWorkhour?.day_end || defaultEndValue)}
            onChange={handleChangeWorkhour}
            ref={dayEndRef}
          >
            {renderTimeslotOptions()}
          </select>
        </div>
      </div>
    </div>
  )
}

export default Field;
