document.addEventListener('DOMContentLoaded', () => {
  $('.keyword-trigger').on('keyup', checkKeywords);
});

function checkKeywords() {
  let keywords = ['Advokat', 'advokat', 'Attorney', 'attorney', '@'];
  let text = $('.keyword-trigger').val();
  let foundKeyword = false;

  for (let index = 0; index < keywords.length; ++index) {
    if(text.indexOf(keywords[index]) != -1) {
      foundKeyword = true;
    }
  }

  if(foundKeyword == true) {
    let html = insertAlertBox();
    if($('#alert-keyword').length == 0) {
      $('.keyword-trigger').after(html);
    }
  } else {
    $('#alert-keyword').remove();
  }
}

function insertAlertBox() {
  return $.parseHTML(`
    <div id="alert-keyword" class="alert alert-warning shadow-sm mt-2" role="alert">
      <small>
        <p class="mb-2"><strong>${I18n.t('offer.alert_box.be_aware')}</strong></p>
        <ul class="mb-0">
          <li>
            <p>${I18n.t('offer.alert_box.attorney_description')}</p>
          </li>
          <br>
          <li>
            <p>${I18n.t('offer.alert_box.email_description')}</p>
          </li>
        </ul>
      </small>
    </div>
  `);
}
