import React from "react"

const Filter = ({ onClick, active, children }) => {
  return (
    <li className="nav-item">
      <a
        onClick={onClick}
        className={`nav-link d-flex align-items-center cursor-pointer ${active ? " active" : ""}`}
      >
        {children}
      </a>
    </li>
  )
}

const SelectFilter = ({ onChange, value, name, children }) => {
  return (
    <select
      onChange={onChange}
      value={value}
      name={name}
      className="custom-select"
    >
      <option value="">{window.I18n.t("shared.all")}</option>
      {children}
    </select>
  )
}

const Filters = ({ currentFilter, setCurrentFilter, counter, availableAssignees, availableCategories, availableSegments, availableSources }) => {
  const availableFilters = ["unassigned", "my_leads", "all_leads"]

  const handleFilterClick = (newFilter) => {
    setCurrentFilter({ ...currentFilter, filter: newFilter })
  }

  const handleFilter = (event) => {
    const filterName = event.target.name;
    const filterValue = event.target.value;

    setCurrentFilter({ ...currentFilter, [filterName]: filterValue })
  }

  const availableAssigneeOptions = () => {
    let options = []

    availableAssignees.forEach((assignee) => {
      options.push(<option key={assignee.gid} value={assignee.gid}>{assignee.name}</option>)
    })

    return options
  }

  const availableCategoryOptions = () => {
    let options = []

    availableCategories.forEach((category) => {
      options.push(<option key={category.id} value={category.id}>{category.title}</option>)
    })

    return options
  }

  const availableSegmentOptions = () => {
    let options = []

    availableSegments.sort((a, b) => a.partner.name.localeCompare(b.partner.name)).forEach((segment) => {
      options.push(<option key={segment.id} value={segment.id}>{segment.partner.name } - {segment.name}</option>)
    })

    return options
  }

  const availableSourceOptions = () => {
    let options = []

    availableSources.forEach((source) => {
      options.push(<option key={source} value={source}>{source}</option>)
    })

    return options
  }

  const availableKindOptions = () => {
    let options = []

    options.push(<option key="personal" value="0">{I18n.t("activerecord.attributes.lead.kinds.personal")}</option>)
    options.push(<option key="business" value="1">{I18n.t("activerecord.attributes.lead.kinds.business")}</option>)

    return options
  }

  return (
    <>
      <ul className="nav nav-pills flex-grow-1 mb-2">
        {availableFilters.map((filter) => {
          return (
            <Filter
              key={filter}
              onClick={() => handleFilterClick(filter)}
              active={currentFilter.filter === filter}
            >
              {window.I18n.t("leads.filters." + filter)}
              <div className="badge d-flex align-items-center">
                {Object.keys(counter).includes(filter) ? counter[filter] : <div className="spin spin-xxxs spin-light" />}
              </div>
            </Filter>
          )
        })}
      </ul>

      <div className="row row-spaced-1">
        <div className="col-12 col-sm-6 col-md">
          <label className="form-label">{I18n.t("activerecord.attributes.lead.assignee")}</label>
          <SelectFilter
            onChange={handleFilter}
            value={currentFilter.assignee_gid}
            name="assignee_gid"
          >
            {availableAssigneeOptions()}
          </SelectFilter>
        </div>

        <div className="col-12 col-sm-6 col-md">
          <label className="form-label">{I18n.t('leads.filters.contact_status.label')}</label>
          <SelectFilter
            onChange={handleFilter}
            value={currentFilter.acts_id_null}
            name="acts_id_null"
          >
            <option value='true'>{I18n.t('leads.filters.contact_status.not_contacted')}</option>
            <option value='false'>{I18n.t('leads.filters.contact_status.contacted')}</option>
          </SelectFilter>
        </div>

        <div className='col-sm-6 col-md'>
          <label className='form-label'>{I18n.t('activerecord.attributes.lead.channel')}</label>
          <SelectFilter
            onChange={handleFilter}
            value={currentFilter.channel_eq}
            name='channel_eq'
          >
            <option value='0'>{window.I18n.t('leads.filters.channel.video')}</option>
            <option value='1'>{window.I18n.t('leads.filters.channel.phone')}</option>
            <option value='2'>{window.I18n.t('leads.filters.channel.email')}</option>
            <option value='3'>{window.I18n.t('leads.filters.channel.physical')}</option>
            <option value='4'>{window.I18n.t('leads.filters.channel.internet_bank_message')}</option>
          </SelectFilter>
        </div>

        <div className='col-sm-6 col-md'>
          <label className='form-label'>{I18n.t('activerecord.attributes.lead.kind')}</label>
          <SelectFilter
            onChange={handleFilter}
            value={currentFilter.kind_eq}
            name='kind_eq'
          >
            {availableKindOptions()}
          </SelectFilter>
        </div>

        <div className='col-sm-6 col-md'>
          <label className='form-label'>{I18n.t('activerecord.attributes.lead.category_id')}</label>
          <SelectFilter
            onChange={handleFilter}
            value={currentFilter.category_id}
            name='category_id'
          >
            {availableCategoryOptions()}
          </SelectFilter>
        </div>

        <div className='col-sm-6 col-md'>
          <label className='form-label'>{I18n.t('activerecord.attributes.lead.source')}</label>
          <SelectFilter
            onChange={handleFilter}
            value={currentFilter.source_eq}
            name='source_eq'
          >
            {availableSourceOptions()}
          </SelectFilter>
        </div>

        <div className='col-sm-6 col-md'>
          <label className='form-label'>{I18n.t('activerecord.attributes.lead.segment_id')}</label>
          <SelectFilter
            onChange={handleFilter}
            value={currentFilter.segment_id}
            name='segment_id'
          >
            {availableSegmentOptions()}
          </SelectFilter>
        </div>

        <div className='col-12'>
          <input
            onChange={handleFilter}
            value={currentFilter.search}
            name="search"
            type="text"
            className="form-control"
            placeholder={window.I18n.t("leads.filters.search")} />
        </div>
      </div>
    </>
  )
}

export default Filters;
