import React from "react"

const State = ({ onClick, active, children }) => {
  return (
    <li className="nav-item">
      <a
        onClick={onClick}
        className={`nav-link${active ? " active" : ""}`}
      >
        {children}
      </a>
    </li>
  )
}

const States = ({ currentState, setCurrentState, counter }) => {
  const availableStates = ["overview", "all", "unhandled", "sent_qualification_flow", "completed_qualification_flow", "booked", "handled", "rejected"]

  return (
    <ul className="nav nav-tabs flex-grow-1">
      {availableStates.map((state) => {
        return (
          <State
            key={state}
            onClick={() => setCurrentState(state)}
            active={currentState === state}
          >
            {window.I18n.t("leads.states." + state)}
            <div className="badge d-flex align-items-center">
              {Object.keys(counter).includes(state) ? counter[state] : <div className="spin spin-xxxs spin-light" />}
            </div>
          </State>
        )
      })}
    </ul>
  )
}

export default States;
