import React, { useState } from "react";
import TypeField from "./Fields/TypeField";
import ChannelField from "./Fields/ChannelField";
import { dateTimeFromTimestamp, timestampFromDateTime } from '../Helpers/TimeFormatters';

const Fields = ({ title,
                  setTitle,
                  startsAt,
                  setStartsAt,
                  endsAt,
                  setEndsAt,
                  participants,
                  setParticipants,
                  newParticipant,
                  setNewParticipant,
                  type,
                  setType,
                  channel,
                  setChannel,
                  handleAddParticipant }) => {
  return (
    <>
      <div className="form-group">
        <label
          htmlFor="event_title"
          className="small"
        >
          {window.I18n.t('communication.event.edit_event.title')}
        </label>
        <input
          type="text"
          className="form-control form-control-sm"
          id="event_title"
          value={title}
          onChange={(e) => setTitle(e.target.value)} />
      </div>

      <div className="form-group">
        <label
          htmlFor="event_start"
          className="small"
        >
          {window.I18n.t('communication.event.edit_event.start')}
        </label>
        <input
          type="datetime-local"
          className="form-control form-control-sm"
          id="event_start"
          value={dateTimeFromTimestamp(startsAt)}
          onChange={(e) => setStartsAt(timestampFromDateTime(e.target.value))} />
      </div>

      <div className="form-group">
        <label
          htmlFor="event_end"
          className="small"
        >
          {window.I18n.t('communication.event.edit_event.end')}
        </label>
        <input
          type="datetime-local"
          className="form-control form-control-sm"
          id="event_end"
          value={dateTimeFromTimestamp(endsAt)}
          onChange={(e) => setEndsAt(timestampFromDateTime(e.target.value))} />
      </div>

      {startsAt > endsAt && (
        <div className="alert alert-danger">
          <small>{window.I18n.t("communication.event.fields.starts_at_must_be_before_ends_at")}</small>
        </div>
      )}

      <div className="form-group">
        <label
          htmlFor="event_participants"
          className="small"
        >
          {window.I18n.t("communication.event.edit_event.participants")} ({window.I18n.t("communication.event.participants.description", { count: participants.length })})
        </label>
        <ul className="list-unstyled">
          {participants.map((participant, index) => (
            <li
              key={index}
              className="d-flex justify-content-between"
            >
              <small>{participant}</small>
              <i
                className="ph ph-x cursor-pointer text-danger"
                onClick={() => setParticipants(participants.filter((p) => p != participant))} />
            </li>
          ))}
          <li className="mt-2">
            <div className="input-group">
              <input
                type="text"
                className="form-control form-control-sm"
                value={newParticipant}
                onChange={(e) => setNewParticipant(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key == "Enter") handleAddParticipant();
                }} />
              <div className="input-group-append">
                <button
                  className="btn btn-light btn-sm"
                  onClick={handleAddParticipant}
                >
                  <i className="ph ph-plus cursor-pointer" />
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <TypeField type={type} setType={setType} />
      <ChannelField channel={channel} setChannel={setChannel} />
    </>
  )
}

export default Fields;
