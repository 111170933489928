import daLocale from '@fullcalendar/core/locales/da';
import svLocale from '@fullcalendar/core/locales/sv';
import fiLocale from '@fullcalendar/core/locales/fi';
import ptLocale from '@fullcalendar/core/locales/pt';
import noLocale from '@fullcalendar/core/locales/nn';
import enLocale from '@fullcalendar/core/locales/en-gb';

const locale = () => {
  switch (I18n.locale.substring(0, 2)) {
    case "da":
      return daLocale;
    case "sv":
      return svLocale;
    case "fi":
      return fiLocale;
    case "pt":
      return ptLocale;
    case "no":
      return noLocale;
    default:
      return enLocale;
  }
}

export default locale;
