export const dateTimeFromTimestamp = (timestamp) => {
  return moment.unix(timestamp).format("YYYY-MM-DDTHH:mm:ss");
}

export const timestampFromDateTime = (datetimeLocal) => {
  return moment(datetimeLocal).unix();
}

export const hoursMinutesSecondsInUTC = (time) => {
  let localMoment = moment(time, "HH:mm:ss");
  return localMoment.utc().format("HH:mm:ss");
}

export const hoursMinutesSecondsInLocal = (time) => {
  let utcMoment = moment.utc(time, "HH:mm:ss");
  return utcMoment.local().format("HH:mm:ss");
}
