$(function () {
  if ($("[id$=score]").length > 0) {
    $("[id$=score]").each(function (e) {
      var stars = $(this).parent('.input-field').find('li.star');

      for (i = 0; i < $(this).val(); i++) {
        $(stars[i]).find('i').removeClass('ph ph-star ph-thin').addClass('ph ph-star ph-fill');
      }
    });
  }

  $('.list-stars li').on('click', function () {
    var onStar = parseInt($(this).data('value'), 10);
    var stars = $(this).parent().children('li.star');

    for (i = 0; i < stars.length; i++) {
      $(stars[i]).find('i').removeClass('ph ph-star ph-fill').addClass('ph ph-star ph-thin');
    }

    for (i = 0; i < onStar; i++) {
      $(stars[i]).find('i').removeClass('ph ph-star ph-thin').addClass('ph ph-star ph-fill');
    }

    $(this).parents('.input-field').find("[id^=review]").val(onStar);
  });

  $('body').on('click', '.rating-hover > .close', function () {
    hide_rating_popup();
  });

  $('.rating-stars').mouseleave(function () {
    hide_rating_popup();
  });

  $('.list-stars.ph.ph-star.ph-thin').hover(function () {
    $(this).closest('.rating-stars').find('.rating-hover').css("display", "block");
  });
});

function hide_rating_popup() {
  $('.rating-hover').css("display", "none");
}
