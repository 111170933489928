window.currentAuth = function () {
  return JSON.parse(window.getCookieFromBrowser('user_info') || '{}');
}

window.refreshJwt = function () {

  const url = '/jwts/refresh'

  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content,
    },
  }).then(response => {
    if (!response.ok) {
      throw new Error('Failed to refresh JWT');
    }
  })
}

window.getCookieFromBrowser = function(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      let cookieValue = c.substring(name.length, c.length);
      return decodeURIComponent(cookieValue); // Decode each cookie value individually
    }
  }
  return "";
}

// Update the last activity time on user interaction
window.lastActivityTime = moment();
window.updateLastActivityTime = () => {
  lastActivityTime = moment();
}

window.timeSinceLastActivity = () => {
  return moment().diff(lastActivityTime, 'minutes');
}

window.currentAuthExpiryInSeconds = () => {
  return moment(currentAuth().expires).diff(moment(), 'seconds');
}

// Check if the modal should be popped.
// The modal should be shown if the user has been inactive for more than 15 minutes
// and the JWT is about to expire in the next 5 minutes
window.shouldShowExtendLoginModal = () => {
  return timeSinceLastActivity() > 15 &&
    (
      currentAuthExpiryInSeconds() > 0 &&
      currentAuthExpiryInSeconds() <= 5 * 60
    )
}

document.addEventListener('DOMContentLoaded', () => {
  // Add event listeners for various user interactions
  window.addEventListener('click', updateLastActivityTime);
  window.addEventListener('scroll', updateLastActivityTime);
  window.addEventListener('keypress', updateLastActivityTime);

  setInterval(() => {
    if (Object.keys(currentAuth()).length) {
      // Check if there was activity in the last 15 minutes
      if (timeSinceLastActivity() <= 15) {
        let diffInSeconds = currentAuthExpiryInSeconds();

        // Check if the expiration time is within 5 minutes from now
        if (diffInSeconds >= 0 && diffInSeconds <= 5 * 60) {
          refreshJwt();
        }
      }
    }
  }, 60 * 1000); // check every minute
});
