import React from "react";

const Loader = ({ width, height }) => {
  return (
    <div className="simple-loader-container bg-transparent">
      <div
        className="simple-loader"
        style={{width: width, height: height}}/>
    </div>
  )
}

export default Loader;
