document.addEventListener('DOMContentLoaded', () => {
  const cvrInput = $('.cvr-fetcher')
  if (cvrInput) {
    var lastValue = cvrInput.val();

    cvrInput.on('blur', function (event) {
      var value = cvrInput.val();
      if (value.length > 0 && value != lastValue) {
        let button = cvrInput.parent().find('button')
        if (button.length) {
          button.prop('disabled', true)
        }

        $.get({
          url: "/api/v1/businesses/cvr",
          data: {
            cvr: value
          },
          success: function (data) {
            if (button.length)
              button.prop('disabled', false)

            cvrInput.removeClass("is-invalid")
            cvrInput.addClass("is-valid")

            $(".cvr-target").each(function () {
              $(this).val(data[$(this).data("key")]);
            });

            $(".cvr-result").removeClass("d-none")
          },
          error: function (e) {
            if (button.length)
              button.prop('disabled', false)

            cvrInput.removeClass("is-valid")
            cvrInput.addClass("is-invalid")
            $(".cvr-result").addClass("d-none")
          }
        })
      }
      else if (value.length == 0) {
        cvrInput.removeClass("is-invalid")
        cvrInput.removeClass("is-valid")
        $(".cvr-result").addClass("d-none")
      }
      lastValue = cvrInput.val();
    });
  }

});
