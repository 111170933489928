window.defaultDateFormat = 'dd/mm-yyyy';

window.changeDateFormat = function(str = '', inputFormat = defaultDateFormat, outputFormat = 'longDate') {
  if(str.length < 1) return undefined;
  if(str == defaultDateFormat) return undefined;
  if(str == undefined) return undefined;

  let str_default = toDefaultDateFormat(str, inputFormat);
  let formattedDate = fromDefaultDateFormat(str_default, outputFormat);
  return formattedDate;
}

window.toDefaultDateFormat = function(str, inputFormat) {
  let date = 'Invalid date';
  moment.locale(I18n.locale);
  switch(inputFormat) {
    case 'yyyy-mm-dd':
      if (str.length != 10) return undefined;
      date = str;
      break;
    case 'dd/mm-yyyy':
      if (str.length != 10) return undefined;
      date = moment(str, 'DD/MM-YYYY').format('YYYY-MM-DD');
      break;
    case 'mm/dd-yyyy':
      if (str.length != 10) return undefined;
      date = moment(str, 'MM/DD-YYYY').format('YYYY-MM-DD');
      break;
    case 'dd. month yyyy':
      date = moment(str).format('YYYY-MM-DD');
      break;
    default:
      console.log("inputFormat is undefined or isn't supported in toDefaultDateFormat");
      break;
  }
  if (date == 'Invalid date') return undefined;
  return date;
}

window.fromDefaultDateFormat = function(str, outputFormat) {
  if(str == undefined) return undefined;
  if(str.length != 10) return undefined;

  let date = 'Invalid date';
  switch(outputFormat) {
    case 'shortDate':
      date = I18n.l('date.formats.short', str);
      break;
    case 'dotted':
      date = I18n.l('date.formats.dotted', str);
      break;
    case 'longDate':
      // Danish: 13. juli 2022
      // English: July 13, 2022
      date = I18n.l('date.formats.long', str);
      break;
    default:
      console.log("outputFormat is undefined or isn't supported in fromDefaultDateFormat");
      break;
  }
  if (date == 'Invalid date') return undefined;
  return date;
}
