window.ArrayMethods = {
  last: function (arr) {
    return arr[arr.length - 1];
  },
  getAt: function (arr, offset) {
    return arr[offset > 0 ? Math.min(arr.length - 1, offset) : Math.max(0, arr.length - 1 + offset)];
  },
  commaSeparated: function (arr, separator = "and") {
    var result = "";

    var i;
    for (i = 0; i < arr.length; ++i) {
      switch (i) {
        case 0: break;
        case arr.length - 1:
          result += ` ${separator} `;
          break;
        default:
          result += ", ";
      }
      result += arr[i];
    }

    return result;
  },
  removeAt: function (arr, index) {
    return arr.filter((x, i, a) => a.indexOf(x) != index);
  }
};
