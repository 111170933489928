export const fetchLeads = ({ userId, state, filter, page, sorting }) => {
  const formData = new FormData();
  formData.append("user_id", userId);
  formData.append("state", state);
  formData.append("filter", filter.filter);
  formData.append("page", page)
  formData.append("assignee_gid", filter.assignee_gid);
  formData.append("category_id", filter.category_id);
  formData.append("segment_id", filter.segment_id);
  formData.append("q[kind_eq]", filter.kind_eq);
  formData.append("q[channel_eq]", filter.channel_eq);
  formData.append("q[source_eq]", filter.source_eq);
  formData.append("q[acts_id_null]", filter.acts_id_null);
  formData.append("q[customer_name_or_customer_email_or_mediator_email_cont]", filter.search);
  formData.append("q[s]", sorting.sort_by + " " + sorting.sort_order);

  const url = "/leads?" + new URLSearchParams(formData).toString();

  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })
}

export const countLeads = ({ userId, state }) => {
  const url = "/leads/count?" + new URLSearchParams({ user_id: userId, state: state }).toString();

  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })
}

export const fetchLead = ({ leadId }) => {
  const url = `/leads/${leadId}`;

  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })
}

export const createLead = (atrs) => {
  const url = "/leads";

  return fetch(url, {
    method: "POST",
    body: JSON.stringify({ lead: atrs }),
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })
}

export const updateLead = ({ leadId, atrs }) => {
  const url = `/leads/${leadId}`;

  return fetch(url, {
    method: "PATCH",
    body: JSON.stringify({ lead: atrs }),
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })
}

export const rejectLead = ({ leadId, atrs }) => {
  const url = `/leads/${leadId}/reject`;

  return fetch(url, {
    method: "PATCH",
    body: JSON.stringify({ lead: atrs }),
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })
}

export const unhandleLead = ({ leadId }) => {
  const url = `/leads/${leadId}/unhandle`;

  return fetch(url, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })
}

export const sendLeadQualificationFlow = ({ leadId }) => {
  const url = `/leads/${leadId}/send_qualification_flow`;

  console.log(url);
  return fetch(url, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })
}

export const prioritiseLead = ({ leadId }) => {
  const url = `/leads/${leadId}/toggle_prioritised`;

  return fetch(url, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })
}

export const fetchAvailableAssignees = () => {
  const url = "/leads/available_assignees";

  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })
}

export const fetchActs = ({ leadId }) => {
  const url = `/leads/${leadId}/acts`;

  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })
}

export const fetchEvents = ({ leadId }) => {
  const url = `/leads/${leadId}/events`;

  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })
}

export const fetchCategories = () => {
  const url = "/categories";

  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })
}

export const fetchSources = () => {
  const url = "/leads/available_sources";

  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })
}

export const fetchSegments = () => {
  const url = "/leads/available_segments";

  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })
}

export const fetchProducts = () => {
  const url = "/leads/available_products";

  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
    }
  })
}

export const fetchRejectionCategories = () => {
  const url = "/rejection_categories";

  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
    }
  })
}
