$(document).ready(function() {
  if ($('#document-supplier-review-modal').length) {
    $('#new-case-file').submit(function(e) {
      $('#create-case').attr('disabled', true);
      hideError();
      showSpinner(I18n.t('documents.supplier_review_modal.loading'));
    });

    $('#new-case-file').on('ajax:success', function(e) {
      let [_data, _status, xhr] = e.detail;
      
      setTimeout(function() {
        hideSpinner();
        showSpinner(I18n.t('documents.supplier_review_modal.redirecting'));
      }, 1000);

      setTimeout(function() {
        window.location.href = _data.URL;
      }, 2000);
    });

    $('#new-case-file').on('ajax:error', function(e) {
      let [_data, _status, xhr] = e.detail;
      $('#create-case').attr('disabled', false);
      showError(_data.error.message);
      hideSpinner();
    })
  }
});

function showSpinner(message) {
  $('#document-supplier-review-modal').find('.modal-dialog').prepend(`
    <div class="loader-overlay">
      <div class="loader">&nbsp;</div>
      <div class="loader-explain">${message}</div>
    </div>
  `);
}

function hideSpinner() {
  $('#document-supplier-review-modal').find('.loader-overlay').remove();
}

function showError(error) {
  $('#document-supplier-review-modal').find('.error').find('.message').text(error);
  $('#document-supplier-review-modal').find('.error').fadeIn();
}

function hideError() {
  $('#document-supplier-review-modal').find('.error').hide();
}
