import React from 'react';

const Participants = ({ participants }) => {
  if (participants.length == 0) return <></>;

  const statusIcon = (status) => {
    switch (status) {
      case "yes":
        return "ph-check-circle text-success";
      case "no":
        return "ph-x-circle text-danger";
      case "noreply":
        return "ph-question text-muted";
    }
  }

  return (
    <div className="d-flex justify-content-between overflow-hidden">
      <i className="ph ph-users ph-xl d-block mt-1 mr-3" />
      <div className="flex-grow-1">
        {window.I18n.t('communication.event.participants.description', { count: participants.length })}

        <ul className="list-unstyled mt-1">
          {participants.map((participant, index) => (
            <li
              className="d-flex align-items-center gap-1 mb-1"
              key={index}
            >
                <i className={`ph ${statusIcon(participant.status)} ph-lg`} />
                {[participant.name, participant.email, participant.phoneNumber].filter(Boolean).join(" - ")}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Participants;
