import React, { useState, useEffect } from "react";
import NewEvent from "./NewEvent";

const NewModal = ({ startsAt, endsAt, leadId, setEvents, calendarToken }) => {
  return (
    <div className="modal fade" key="new-event-modal" id="new-event-modal">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content rounded-5 border-0">
          <div className="modal-header p-4">
            <h5 className="modal-title" id="exampleModalLabel">{I18n.t('communication.event.new_modal.title')}</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body p-4">
            <NewEvent
              _startsAt={startsAt}
              _endsAt={endsAt}
              _leadId={leadId}
              setEvents={setEvents}
              calendarToken={calendarToken}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewModal;
