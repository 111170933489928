$(document).ready(function() {
  if ($('#new-case-file-attachment').length) {
    $('#new-case-file-attachment').on('ajax:success', function(e) {
      let [_data, _status, xhr] = e.detail;

      $('.file-preview').remove(); // Empty files in upload form
      $('.attach-file-container').parents('.card-body').find('#attachment_pane').find('tbody').append(_data) // Append newly uploaded files to file list
      document.dispatchEvent(new Event('caseFileAttachmentUploaded')); // Dispatch New Case File Event to update timeline
      CustomToastr.success(I18n.t('shared.case_file_attachments.files_uploaded')); // Broadcast   
    });
  }
});

window.addFileToNewInput = function(file, newInput) {
  if (!newInput) { return }
  var dataTransfer = new DataTransfer()
  dataTransfer.items.add(file)
  newInput.files = dataTransfer.files
}

window.addFileNameToPreview = function(file, preview) {
  if (!preview) { return }
  preview.innerText = file.name
}

window.breakIntoSeparateFiles = function(input) {
  var $input = $(input)
  var targetSelector = $input.data('targetselector');
  var templateSelector = $input.data('templateselector');

  if (!input.files) { return }
  for (var file of input.files) {
    handleNewFile(file, templateSelector, targetSelector);
  }
  $input.val([])
}

window.dragEnter = function(ev) {
  $(ev.target).addClass('active');
  // Prevent default behavior (Prevent file from being opened)
  ev.preventDefault();
}

window.dragLeave = function(ev) {
  $(ev.target).removeClass('active');
  // Prevent default behavior (Prevent file from being opened)
  ev.preventDefault();
}

window.dragOverHandler = function(ev) {
  // Prevent default behavior (Prevent file from being opened)
  ev.preventDefault();
}

window.dropHandler = function(ev) {
  $(ev.target).removeClass('active');
  var inputSelector = $(ev.target).data('inputselector');
  var $input = $(inputSelector);
  var targetSelector = $input.data('targetselector');
  var templateSelector = $input.data('templateselector');

  // Prevent default behavior (Prevent file from being opened)
  ev.preventDefault();
  if (ev.dataTransfer.items) {
    // Use DataTransferItemList interface to access the file(s)
    for (var i = 0; i < ev.dataTransfer.items.length; i++) {
      // If dropped items aren't files, reject them
      if (ev.dataTransfer.items[i].kind === 'file') {
        var file = ev.dataTransfer.items[i].getAsFile()
        handleNewFile(file, templateSelector, targetSelector);
      }
    }
  } else {
    // Use DataTransfer interface to access the file(s)
    for (var i = 0; i < ev.dataTransfer.files.length; i++) {
      var file = ev.dataTransfer.files[i];
      handleNewFile(file, templateSelector, targetSelector);
    }
  }
} 

window.handleNewFile = function(file, templateSelector, targetSelector) {
  var templateHtml = $(templateSelector).html()
  var $newFile = $(templateHtml).appendTo(targetSelector)
  addFileToNewInput(file, $newFile.find("input")[0])
  addFileNameToPreview(file, $newFile.find(".file-name")[0])
}
