import React from "react"

const leadSubjectUrl = (leadSubject) => {
  let root;

  switch (window.currentAuth().user_type) {
    case "Admin":
      root = "/manage"
      break;
    case "Advisor":
      root = "/supplier"
      break;
  }

  switch (leadSubject.subject_type) {
    case "CaseFile":
      const appendment = window.currentAuth().user_type == "Admin" ? "/edit" : "";
      return `${root}/case_files/${leadSubject.subject_id}${appendment}`
    case "Submission":
      return `${root}/submissions/${leadSubject.subject.access_token}`
  }
}

const LeadSubjectIcon = ({ leadSubject, className }) => {
  switch (leadSubject.subject_type) {
    case "CaseFile":
      return <i className={`ph ph-archive ph-xxl ph-thin ${className}`} />
    case "Submission":
      return <i className={`ph ph-folders ph-xxl ph-thin ${className}`} />
  }
}

const LeadSubjectDescription = ({ leadSubject }) => {
  switch (leadSubject.subject_type) {
    case "CaseFile":
      return <h6>{leadSubject.subject.case_no}</h6>
    case "Submission":
      return <h6>{leadSubject.subject.name}</h6>
  }
}

const LeadSubjects = ({ leadSubjects }) => {
  return (
    <div className="d-flex flex-column gap-2">
      {leadSubjects.map((leadSubject, index) => (
        <a
          href={leadSubjectUrl(leadSubject)}
          key={index}
          className="d-flex justify-content-between align-items-center border rounded-lg shadow cursor-pointer animated-last-arrow px-3 py-2"
        >
          <div className="d-flex align-items-center">
            <LeadSubjectIcon
              leadSubject={leadSubject}
              className="mr-1" />
            <LeadSubjectDescription leadSubject={leadSubject} />
          </div>
          <i className="ph ph-arrow-right ph-thin ml-1" />
        </a>
      ))}
    </div>
  )
}

export default LeadSubjects;
