window.CustomToastr = class CustomToastr {
  static success(message, options = toastr.options) {
    const toastrOptions = toastr.options;
    toastr.options = { ...toastr.options, ...options };
    toastr.success(`<div class='d-flex align-items-center'><div class='ph ph-check-circle ph-fill text-success ph-xxl mr-2'></div><span>${message}</span></div>`);
    toastr.options = toastrOptions;
  }

  static error(message, options = toastr.options) {
    const toastrOptions = toastr.options;
    toastr.options = { ...toastr.options, ...options };
    toastr.error(`<div class='d-flex align-items-center'><div class='ph ph-x-circle ph-fill text-danger ph-xxl mr-2'></div><span>${message}</span></div>`);
    toastr.options = toastrOptions;
  }

  static toast(type, message, options = toastr.options) {
    switch (type) {
      case "notice":
      case "success":
        return CustomToastr.success(message, options);
      default:
        return CustomToastr.error(message, options);
    }
  }
};
