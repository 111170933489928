import React from "react"

const LeadCalls = ({ acts }) => {
  return (
    <div className="d-flex align-items-center">
      <i className="ph ph-phone ml-2 mr-1" />
      ({acts.length})
    </div>
  )
}

export default LeadCalls
