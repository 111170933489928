import React, { useState, useEffect } from "react";
import Fields from './Fields';

const EditEvent = ({ event, setEvent, setEvents, setEditable, calendarToken }) => {
  const [title, setTitle] = useState(event.title);
  const [startsAt, setStartsAt] = useState(event.when.startTime);
  const [endsAt, setEndsAt] = useState(event.when.endTime);
  const [participants, setParticipants] = useState(event.participants.map((participant) => participant.email));
  const [newParticipant, setNewParticipant] = useState("");
  const [type, setType] = useState(event.metadata.type);
  const [channel, setChannel] = useState(event.metadata.channel);

  const [updateEventLoading, setUpdateEventLoading] = useState(false);

  const handleAddParticipant = (e) => {
    setParticipants([...participants, newParticipant]);
    setNewParticipant("");
  }

  const handleUpdateEvent = () => {
    setUpdateEventLoading(true);

    fetch(`/communication/calendar_tokens/${calendarToken.id}/events/${event.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content,
      },
      body: JSON.stringify({
        event: {
          title: title,
          startsAt: startsAt,
          endsAt: endsAt,
          participants: participants,
          video: channel == "video", // TODO: Remove this property once Björn has made sure that a video meeting will be created if channel == "video"
          channel: channel,
          type: type,
        }
      })
    })
      .then((response) => response.json())
      .then((data) => {
        setEvent(data);
        setEvents((events) => events.map((event) => event.eventId == data.eventId ? data : event));

        setUpdateEventLoading(false);

        // Flip the event card back to the show event side
        setEditable(false);
      })
  }

  return (
    <>
      <h5 className="mb-1">{window.I18n.t('communication.event.edit_event.editing')}</h5>

      <Fields
        title={title}
        setTitle={setTitle}
        startsAt={startsAt}
        setStartsAt={setStartsAt}
        endsAt={endsAt}
        setEndsAt={setEndsAt}
        participants={participants}
        setParticipants={setParticipants}
        newParticipant={newParticipant}
        setNewParticipant={setNewParticipant}
        handleAddParticipant={handleAddParticipant}
        type={type}
        setType={setType}
        channel={channel}
        setChannel={setChannel}
      />

      <div className="form-group mb-0">
        <button
          className="btn btn-primary btn-block btn-sm"
          onClick={() => handleUpdateEvent()}
          disabled={updateEventLoading || startsAt > endsAt}
        >
          {window.I18n.t('shared.save')}
        </button>
      </div>
    </>
  )
}

export default EditEvent;
