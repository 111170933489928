import React, { useState, useEffect } from "react";
import Field from "./Field";

const Modal = ({ email }) => {
  const [workhoursTenant, setWorkhoursTenant] = useState([]);
  const [workhoursUser, setWorkhoursUser] = useState([]);

  useEffect(() => {
    const data = fetchWorkhours()
      .then((data) => {
        setWorkhoursTenant(data.workhours_tenant);
        setWorkhoursUser(data.workhours_user);
      })
  }, []);

  const fetchWorkhours = async() => {
    const response = await fetch('/communication/workhours');
    const data = await response.json()

    return data;
  }

  const renderWorkhoursFields = () => {
    // If we haven't retrieved the workhours yet, then don't render anything
    if (workhoursTenant.length == 0 && workhoursUser.length == 0) return <></>

    const days = [0, 1, 2, 3, 4, 5, 6];

    return days.map((day, index) => {
      return <Field
        email={email}
        key={index}
        day={day}
        defaultStartValue={workhoursTenant.find((workhour) => workhour.day == day)?.day_start || "09:00:00"}
        defaultEndValue={workhoursTenant.find((workhour) => workhour.day == day)?.day_end || "17:00:00"}
        _currentWorkhour={workhoursUser.find((workhour) => workhour.day == day)}
      />
    });
  }

  return (
    <div className="modal fade" key={`workhours-modal`} id="workhours-modal">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content rounded-5 border-0" style={{ minHeight: '550px' }}>
          <div className="modal-header p-4">
            <h5 className="modal-title" id="exampleModalLabel">{I18n.t('communication.workhour.modal.title')}</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body p-4">
            <div className="row row-spaced-2">
              {renderWorkhoursFields()}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal;
