import React from "react"

import { useTheme } from "@table-library/react-table-library/theme"
import { getTheme } from "@table-library/react-table-library/baseline"
import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
  HeaderCell,
  Cell,
} from "@table-library/react-table-library/table";

import LeadStatus from "./Helpers/LeadStatus"
import LeadCalls from "./Helpers/LeadCalls"
import PrioritiseLead from "./Helpers/PrioritiseLead"

const LeadsTable = ({ leads, setLeads, setOpenLead, sorting, setSorting }) => {
  const theme = useTheme([
    getTheme(),
    {
      Table: `
        --data-table-library_grid-template-columns: 5% 12% 12% 15% 16% 10% 10% 5% 10% 5%;
      `,
      Header: ``,
      Body: ``,
      BaseRow: `

        background-color: transparent;

        &.row-select-selected, &.row-select-single-selected {
          background-color: var(--theme-ui-colors-background-secondary);
          color: var(--theme-ui-colors-text);
        }
      `,
      HeaderRow: `
        font-size: 10px;
        color: var(--theme-ui-colors-text-light);

        .th {
          border-top: 0;
          border-bottom: 1px solid var(--theme-ui-colors-border);
          .sortable-header {
            cursor: pointer;
          }
        }
      `,
      Row: `
        font-size: 12px;
        color: var(--theme-ui-colors-text);

        &:not(:last-of-type) .td {
          border-bottom: 1px solid var(--theme-ui-colors-border);
        }

        &:hover {
          color: var(--theme-ui-colors-text-light);
        }
      `,
      BaseCell: `
        border-bottom: 1px solid transparent;

        padding: 8px;

        svg {
          fill: var(--theme-ui-colors-text);
        }
      `,
      HeaderCell: ``,
      Cell: ``,
    },
  ]);

  const data = { nodes: leads };

  const SortableHeader = (props) => {
    const Caret = () => {
      return (
        sorting.sort_by === props.columnName ?
          sorting.sort_order === 'asc' ?
            <i className="ph-bold ph-caret-up"></i> :
            <i className="ph-bold ph-caret-down"></i>:
          <i className="ph ph-caret-up-down"></i>
      );
    }

    const sort = () => {
      setSorting(s => {
        return {
          sort_by: props.columnName,
          sort_order: (s.sort_by !== props.columnName || s.sort_order === 'desc') ? 'asc': 'desc'
        }
      })
    }

    return (
      <span className="sortable-header" onClick={sort}>
        <Caret />
        {props.children}
      </span>
    )
  }

  return (
    <Table data={data} theme={theme} layout={{custom: true, horizontalScroll: true}}>
      {(tableList) => (
        <>
          <Header>
            <HeaderRow>
              <HeaderCell>
                <SortableHeader columnName={"calls_count"}>
                  {I18n.t("leads.table.labels.calls_count")}
                </SortableHeader>
              </HeaderCell>
              <HeaderCell>
                <SortableHeader columnName={"due_at"}>
                  {I18n.t("leads.table.labels.due")}
                </SortableHeader>
              </HeaderCell>
              <HeaderCell>
                <SortableHeader columnName={"created_at"}>
                  {I18n.t("leads.table.labels.created")}
                </SortableHeader>
              </HeaderCell>
              <HeaderCell>
                <SortableHeader columnName={"customer_name"}>
                  {I18n.t("leads.table.labels.name")}
                </SortableHeader>
              </HeaderCell>
              <HeaderCell>
                {I18n.t("leads.table.labels.description")}
              </HeaderCell>
              <HeaderCell>
                {I18n.t("leads.table.labels.category")}
              </HeaderCell>
              <HeaderCell>
                <SortableHeader columnName={"source"}>
                  {I18n.t("leads.table.labels.source")}
                </SortableHeader>
              </HeaderCell>
              <HeaderCell>
                <SortableHeader columnName={"kind"}>
                  {I18n.t("leads.table.labels.kind")}
                </SortableHeader>
              </HeaderCell>
              <HeaderCell>
                {I18n.t("leads.table.labels.segment")}
              </HeaderCell>
              <HeaderCell />
            </HeaderRow>
          </Header>

          <Body>
            {tableList.map((item) => (
              <Row
                key={item.id}
                item={item}
                className="cursor-pointer"
                onClick={(item, event) => setOpenLead(item)}
              >
                <Cell
                  className={`${item.acts.length == 0 ? 'lead-untouched' : 'lead-touched'}`}
                >
                  <div className="d-flex align-items-center flex-column gap-1">
                    <LeadStatus status={item.status} />
                    {(item.acts.filter((act) => ["Act::IncomingCall", "Act::OutgoingCall"].includes(act.type)).length > 0) && (
                      <LeadCalls acts={item.acts.filter((act) => ["Act::IncomingCall", "Act::OutgoingCall"].includes(act.type))} />
                    )}
                    <PrioritiseLead
                      lead={item}
                      setLead={setOpenLead}
                      setLeads={setLeads} />
                    {item.locale.toUpperCase()}
                  </div>
                </Cell>
                <Cell>
                  {item.due_at ? new Intl.DateTimeFormat(I18n.locale, {dateStyle: 'short',timeStyle: 'short'}).format(new Date(item.due_at)) : "N/A"}
                </Cell>
                <Cell>
                  {new Intl.DateTimeFormat(I18n.locale, {dateStyle: 'short',timeStyle: 'short'}).format(new Date(item.created_at))}
                </Cell>
                <Cell>{item.customer_info.name || "N/A"}</Cell>
                <Cell>
                  {item.acts.length > 0 && (
                    <>
                      <div className="text-truncate-multiline text-truncate-lines-2 small font-weight-light font-italic">
                        {item.acts.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))[0].description}
                      </div>

                      <hr className="border-dashed border-1 m-1" />
                    </>
                  )}
                  {item.description}
                </Cell>
                <Cell>{item.category?.title || "N/A"}</Cell>
                <Cell>{item.source || "N/A"}</Cell>
                <Cell>{item.kind ? I18n.t(`activerecord.attributes.lead.kinds.${item.kind}`) : "N/A"}</Cell>
                <Cell>
                  {item.segment && (
                    <img
                      src={item.segment?.logo_url}
                      alt={item.segment?.name}
                      style={{maxHeight: "30px", maxWidth: "200px", objectFit: "contain"}} />
                  )}
                </Cell>
                <Cell>
                  {item.assignee && (
                    <img
                      src={item.assignee.avatar_url}
                      alt={item.assignee.name}
                      className="rounded-circle"
                      style={{width: "32px", objectFit: "contain"}} />
                  )}
                </Cell>
              </Row>
            ))}
          </Body>
        </>
      )}
    </Table>
  )
}

export default LeadsTable;
