import { useState, useEffect, useCallback } from 'react';

function useLocalStorageToggle(key, initialState = false) {
  const [state, setState] = useState(() => {
    const storedValue = window.localStorage.getItem(key);
    return storedValue !== null ? JSON.parse(storedValue) : initialState;
  });

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  const toggle = useCallback(() => {
    setState(state => !state);
  }, []);

  return [state, toggle];
}

export default useLocalStorageToggle
