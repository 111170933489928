window.openEIdPopup = function(accessUrl = '') {
  $('#e-id-login-btn').prop('disabled', true);

  var popupWidth = 800;
  var popupHeight = 600;
  var left = (screen.width / 2) - (popupWidth / 2);
  var top = (screen.height / 2) - (popupHeight / 2);
  var popupParams = `width=${popupWidth}, height=${popupHeight}, scrollbars=no, resizable=no, toolbar=no, menubar=no, location=no, status=no, left=${left}, top=${top}`;

  var eIdPopup = window.open('', 'e-id-popup', popupParams);

  setTimeout(() => {
    eIdPopup.location.assign(accessUrl);
  });

  return new Promise((resolve, reject) => {
    var sessionInterval = setInterval(function () {
      var sessionData = window.getSessionData();
      if (sessionData['userSignedIn']) {
        eIdPopup.close();
        clearInterval(sessionInterval);
        resolve();
      }
    }, 4000);
  });
}

window.handleSignIn = function() {
  var sessionData = window.getSessionData();
  if (!sessionData['userSignedIn']) return;

  var urlParams = new URLSearchParams(window.location.search);
  var acceptToken = urlParams.get('claim_invitation_accept_token');

  window.location = acceptToken ? `/claim_invitations/${acceptToken}` : (sessionData['redirectUrl'] || '/dashboard');
}
