import React, { useState, useEffect } from "react";
import useLocalStorageToggle from '/app/javascript/hooks/useLocalStorageToggle';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import locale from "./Helpers/FullCalendarLocales";
import calendarLoaderStyle from "./Helpers/CalendarLoaderStyle";
import Modal from "./Event/Modal";
import NewModal from "./Event/NewModal";
import eventTypes from './Helpers/EventTypes';
import './Calendar.scss';

const url = new URL(window.location.href);

const CalendarApp = ({ calendarToken, email }) => {
  const [calendarLoading, setCalendarLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [newEvent, setNewEvent] = useState({});

  const [showWeekend, setShowWeekend] = useLocalStorageToggle('calendar-show-weekend', true);

  // This effect is called once the component mounts.
  //
  // If toggle_new_event_modal param is "true" and there's a lead_id param,
  // then toggle the NewModal and set the lead_id to the state.
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const toggleNewEventModal = urlParams.get("toggle_new_event_modal");
    const leadId = urlParams.get("lead_id");

    if (toggleNewEventModal === "true" && leadId) {
      $('#new-event-modal').modal('show');
      setNewEvent({ ...newEvent, leadId: leadId });
    }
  }, [])

  // This effect is called when the calendarToken is set or changed and when the startDate and endDate is changed.
  // It fetches the events from the backend and sets them in the state.
  useEffect(() => {
    if (Object.keys(calendarToken).length === 0) return;
    if (startDate === "" || endDate === "") return;

    setCalendarLoading(true);

    fetchCalendarEvents(startDate, endDate).then((data) => {
      setEvents(data);
      setCalendarLoading(false);
    })
  }, [calendarToken, endDate]);

  const fetchCalendarEvents = (_startDate, _endDate) => {
    return fetch(`/communication/calendar_tokens/${calendarToken.id}?from=${_startDate}&to=${_endDate}`)
      .then((response) => response.json())
  }

  // This function handles the dates set by the user in the calendar.
  // It is called when the user changes the view or changes the date range.
  const handleDatesSet = (info) => {
    setStartDate(moment(info.start).format("YYYY-MM-DD"));

    // FullCalendar sends the end date as the next day, so we subtract 1 day.
    // Example: Actual end date is set to 31/12-2023 but FullCalendar sends 01/01-2024.
    // See https://fullcalendar.io/docs/datesSet
    setEndDate(moment(info.end).subtract(1, "days").format("YYYY-MM-DD"));

    url.searchParams.set("date", moment(info.start).format("YYYY-MM-DD"));
    window.history.replaceState(null, null, url);
  }

  const formattedEvents = () => {
    return events.map((event) => {
      const isPrivate = event.visibility === "private";
      const isSelected = selectedEvent?.eventId === event.eventId;

      function adjustColor(color, amount) {
        color = color.replace(/^#/, '');
        color = color.replace(/../g, color => {
          const newAmount = Math.min(255, Math.max(0, parseInt(color, 16) + amount));
          const c = '0'+newAmount.toString(16)
          return c.substring(c.length - 2);
        });
        return '#' + color;
      }

      const eventColor = (event) => {
        const type = event.metadata.type;
        let color = '#a2a2a2';

        if(type in eventTypes){
          color = eventTypes[type].color;
        }

        if(isSelected){
          color = adjustColor(color,-30);
        }

        return color;
      }

      return {
        title: isPrivate ? I18n.t("communication.calendar_app.private_title") : event.title,
        start: moment.unix(event.when.startTime).format("YYYY-MM-DDTHH:mm:ss"),
        end: moment.unix(event.when.endTime).format("YYYY-MM-DDTHH:mm:ss"),
        extendedProps: {
          eventId: event.eventId,
          participants: isPrivate ? [] : event.participants,
          conferencing: isPrivate ? {} : event.rawdata?.conferencing || {},
        },
        backgroundColor: eventColor(event),
        borderColor: eventColor(event),
      }
    });
  }

  return (
    <div className="position-relative">
      <NewModal
        startsAt={newEvent.startsAt}
        endsAt={newEvent.endsAt}
        leadId={newEvent.leadId}
        setEvents={setEvents}
        calendarToken={calendarToken}
      />
      <Modal
        event={selectedEvent}
        setEvent={setSelectedEvent}
        setEvents={setEvents}
        calendarToken={calendarToken}
        email={email}
      />
      <div className="card card-body p-4" style={calendarLoading ? calendarLoaderStyle : {}}>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
          initialView="timeGridWeek"
          initialDate={url.searchParams.get("date") || moment().format("YYYY-MM-DD")}
          customButtons={{
            newEventButton: {
              text: window.I18n.t("communication.calendar_app.new_event"),
              click: () => {
                $('#new-event-modal').modal('show');
              },
            },
            toggleWeekendButton: {
              text: showWeekend ?
                window.I18n.t("communication.calendar_app.weekends_hide") :
                window.I18n.t("communication.calendar_app.weekends_show"),
              click: () => {
                setShowWeekend((showWeekend) => !showWeekend)
              },
            },
          }}
          headerToolbar={{
            start: "title",
            center: "dayGridMonth,timeGridWeek,timeGridDay dailyAgenda toggleWeekendButton",
            end: "today prev,next newEventButton",
          }}
          views={{
            timeGridDay: {
              type: "timeGridDay",
              buttonText: window.I18n.t("communication.calendar_app.day_view"),
              nowIndicator: true,
              weekNumbers: true,
            },
            timeGridWeek: {
              type: "timeGridWeek",
              buttonText: window.I18n.t("communication.calendar_app.week_view"),
              nowIndicator: true,
              weekNumbers: true,
            },
            dayGridMonth: {
              type: "dayGridMonth",
              buttonText: window.I18n.t("communication.calendar_app.month_view"),
              weekNumbers: true,
            },
            dailyAgenda: {
              type: "listDay",
              buttonText: "Agenda",
            },
          }}
          selectable={true} // Enable date selection
          select={(info) => {
            setNewEvent({ startsAt: moment(info.start).unix(), endsAt: moment(info.end).unix() });
            $('#new-event-modal').modal('show');
          }}
          locale={locale()}
          events={formattedEvents()}
          eventClick={(info) => {
            setSelectedEvent(events.find((event) => event.eventId === info.event.extendedProps.eventId));
          }}
          datesSet={handleDatesSet}
          height={'max(600px, calc(100vh - 255px))'} // 600px is enough to show 8-17, calc(100vh - 255px) is using full height of screen
          scrollTime={'08:00:00'}
          weekends={showWeekend}
        />
      </div>
    </div>
  )
}

export default CalendarApp;
