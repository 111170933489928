import React from 'react';
import Conferencing from './Conferencing';
import Participants from './Participants';

const ShowEvent = ({ event }) => {
  const isPrivate = event.visibility == "private";

  return (
    <>
      <div className="mb-3">
        <h5 className="mb-1">{isPrivate ? I18n.t("communication.calendar_app.private_title") : event.title}</h5>
        {moment.unix(event.when.startTime).format("dddd, MMMM D")} ⋅ {moment.unix(event.when.startTime).format("HH:mm")} - {moment.unix(event.when.endTime).format("HH:mm")}
        <small className="d-block text-muted">{I18n.t("communication.calendar_app.created_by")} {event.metadata.createdBy}</small>
        <small className="d-block text-muted">{I18n.t("communication.calendar_app.event_owner")} {event.owner}</small>
      </div>

      {isPrivate ? (
        <></>
      ) : (
        <>
          {/* Google Meet / Microsoft Teams*/}
          {event.metadata.video && (
            <div className="mb-3">
              <Conferencing
                videoProvider={event.metadata.videoProvider}
                videoUrl={event.metadata.videoUrl}
              />
            </div>
          )}

          {/* Participants */}
          {event.participants.length > 0 && (
            <Participants participants={event.participants} />
          )}

          {/* Lead file */}
          {event.lead_id && (
              <a className="btn btn-primary btn-sm" href={`/leads/?lead_id=${event.lead_id}`} target="_blank">{I18n.t("communication.event.open_lead_file")}</a>
          )}
        </>
      )}
    </>
  )
}

export default ShowEvent;
