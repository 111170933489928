document.addEventListener('DOMContentLoaded', () => {
  window.reloadPopoverEventHandlers();
});

window.reloadPopoverEventHandlers = function() {
  $('[data-toggle="popover"]').popover({
    'html': true,
    'trigger': 'manual',
    'container': 'body',
    'content': function () {
      var el = $(this);

      var modelId = el.data('model-id');
      var modelType = el.data('model-type');

      if (!modelId || !modelType) return null;

      var request = $.ajax({
        url: '/advanced_popovers',
        dataType: 'JSON',
        method: 'GET',
        async: false,
        data: {
          "model_id": modelId,
          "model_type": modelType
        }
      });

      var content = request.responseJSON.html;

      //Update data-content to prevent ajax load on future hover
      el.attr('data-content', content);

      return content;
    }
  }).on("mouseenter", async function () {
    var _this = this; // Trigger

    await sleep(500);

    if ($(_this).is(":hover") == false) return

    $(_this).popover("show");

    var popoverId = $(_this).attr('aria-describedby');
    var popoverEl = $(`#${popoverId}`);

    popoverEl.on("mouseleave", function () {
      setTimeout(function () {
        if (popoverEl.is(":hover") == false && $(_this).is(":hover") == false) {
          $(_this).popover("hide");
        }
      }, 50);
    });

  }).on("mouseleave", function () {
    var _this = this; // Trigger

    var popoverId = $(this).attr('aria-describedby');
    var popoverEl = $(`#${popoverId}`);

    if (!popoverEl.length) return

    setTimeout(function () {
      if (popoverEl.is(":hover") == false && $(_this).is(":hover") == false) {
        $(_this).popover("hide")
      }
    }, 50);
  });
};
