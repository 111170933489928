import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { NylasProvider } from "@nylas/nylas-react";

import App from './App';

const CommunicationWrapper = ({ nylasAuthenticatable }) => {
  return (
    <React.StrictMode>
      <NylasProvider serverBaseUrl="/communication">
        <App nylasAuthenticatable={nylasAuthenticatable} />
      </NylasProvider>
    </React.StrictMode>
  )
};

export default CommunicationWrapper;

document.addEventListener('DOMContentLoaded', () => {
  const domEls = document.querySelectorAll('.communication-calendar');

  domEls.forEach((domEl) => {
    let nylasAuthenticatable = domEl.dataset.nylasAuthenticatable == "true";

    ReactDOM.render(
      <CommunicationWrapper nylasAuthenticatable={nylasAuthenticatable} />,
      domEl
    );
  });
});
