import React, { useState, useEffect } from "react";

import { fetchAvailableAssignees, fetchCategories, fetchSegments } from "./api";

const Fields = ({ formData, handleChange, formName }) => {
  const [availableAssignees, setAvailableAssignees] = useState([]);
  const [categories, setCategories] = useState([]);
  const [segments, setSegments] = useState([]);

  useEffect(() => {
    fetchAvailableAssignees()
      .then((response) => response.json())
      .then((data) => {
        setAvailableAssignees(data)
      })
  }, [])

  useEffect(() => {
    fetchCategories()
      .then((response) => response.json())
      .then((data) => {
        setCategories(data);
      })
  }, [])

  useEffect(() => {
    fetchSegments()
      .then((response) => response.json())
      .then((data) => {
        setSegments(data);
      })
  }, [])

  const availableAssigneeOptions = () => {
    let options = []

    options.push(<option key="default" value="">{I18n.t("shared.select")}</option>)

    availableAssignees.forEach((assignee) => {
      options.push(<option key={assignee.gid} value={assignee.gid}>{assignee.name}</option>)
    })

    return options
  }

  const channelOptions = () => {
    const channels = [
      { id: "0", title: window.I18n.t('leads.filters.channel.video') },
      { id: "1", title: window.I18n.t('leads.filters.channel.phone') },
      { id: "2", title: window.I18n.t('leads.filters.channel.email') },
      { id: "3", title: window.I18n.t('leads.filters.channel.physical') },
      { id: "4", title: window.I18n.t('leads.filters.channel.internet_bank_message') }
    ]

    let options = []
    options.push(<option key="default" value="">{I18n.t("shared.select")}</option>)
    channels.forEach((channel) => {
      options.push(<option key={channel.id} value={channel.id}>{channel.title}</option>)
    })

    return options
  }

  const categoryOptions = () => {
    let options = []

    options.push(<option key="default" value="">{I18n.t("shared.select")}</option>)

    categories.forEach((category) => {
      options.push(<option key={category.id} value={category.id}>{category.title}</option>)
    })

    return options
  }

  const segmentOptions = () => {
    let options = []

    options.push(<option key="default" value="">{I18n.t("shared.select")}</option>)

    segments.sort((a, b) => a.partner.name.localeCompare(b.partner.name)).forEach((segment) => {
      options.push(<option key={segment.id} value={segment.id}>{segment.partner.name } - {segment.name}</option>)
    })

    return options
  }

  const personalKindId = formName +'-personal-kind';
  const businessKindId = formName +'-business-kind';

  return (
    <>
      <div className='form-group'>
        <label
          htmlFor='assigneeGid'
          className='small'
        >
          {I18n.t('activerecord.attributes.lead.assignee')}
        </label>
        <select
          className='form-control custom-select'
          id='assigneeGid'
          name='assignee_gid'
          value={formData.assignee_gid}
          onChange={handleChange}
        >
          {availableAssigneeOptions()}
        </select>
      </div>

      <div className='form-group'>
        <label htmlFor='customerName'>{I18n.t('activerecord.attributes.lead.customer_name')}</label>
        <input
          type='text'
          className='form-control'
          id='customerName'
          name='customer_name'
          value={formData.customer_name}
          onChange={handleChange} />
      </div>

      <div className='form-group'>
        <label htmlFor='customerEmail'>{I18n.t('activerecord.attributes.lead.customer_email')}</label>
        <input
          type="text"
          className="form-control"
          id="customerEmail"
          name="customer_email"
          value={formData.customer_email}
          onChange={handleChange} />
      </div>

      <div className="form-group">
        <label htmlFor="customerPhone">{I18n.t("activerecord.attributes.lead.customer_phone")}</label>
        <input
          type="text"
          className="form-control"
          id="customerPhone"
          name="customer_phone"
          value={formData.customer_phone}
          onChange={handleChange} />
      </div>

      <div className="form-group">
        <label htmlFor="customerSsn">{I18n.t("activerecord.attributes.lead.customer_ssn")}</label>
        <input
          type="text"
          className="form-control"
          id="customerSsn"
          name="customer_ssn"
          value={formData.customer_ssn}
          onChange={handleChange} />
      </div>

      <div className="form-group">
        <label htmlFor="description">{I18n.t("activerecord.attributes.lead.description")}</label>
        <textarea
          rows="5"
          className="form-control"
          id="description"
          name="description"
          value={formData.description}
          onChange={handleChange} />
      </div>

      <div className="form-group">
        <label htmlFor="dueAt">{I18n.t("activerecord.attributes.lead.due_at")}</label>
        <input
          type="datetime-local"
          className="form-control"
          id="dueAt"
          name="due_at"
          value={formData.due_at}
          onChange={handleChange} />
      </div>

      <div className="form-group">
        <label>{I18n.t("activerecord.attributes.lead.kind")}</label>

        <div className="row row-spaced-1">
          <div className="col-12 col-sm-6">
            <label
              htmlFor={personalKindId}
              className="radiocard-label w-100"
            >
              <input
                type="radio"
                name="kind"
                id={personalKindId}
                className="radiocard-input"
                value="personal"
                onChange={handleChange}
              />
              <div className="radiocard-icon with-dot d-flex text-left">
                        <span className="font-weight-light">
                          {I18n.t("activerecord.attributes.lead.kinds.personal")}
                        </span>
              </div>
            </label>
          </div>

          <div className="col-12 col-sm-6">
            <label
              htmlFor={businessKindId}
              className="radiocard-label w-100"
            >
              <input
                type="radio"
                name="kind"
                id={businessKindId}
                className="radiocard-input"
                value="business"
                onChange={handleChange}
              />
              <div className="radiocard-icon with-dot d-flex text-left">
                        <span className="font-weight-light">
                          {I18n.t("activerecord.attributes.lead.kinds.business")}
                        </span>
              </div>
            </label>
          </div>
        </div>
      </div>

      <div className="form-group">
        <label
          htmlFor="channel"
          className="small"
        >
          {I18n.t("activerecord.attributes.lead.channel")}
        </label>
        <select
          className="form-control custom-select"
          id="channel"
          name="channel"
          value={formData.channel}
          onChange={handleChange}
        >
          {channelOptions()}
        </select>
      </div>

      <div className="form-group">
        <label
          htmlFor="categoryId"
          className="small"
        >
          {I18n.t("activerecord.attributes.lead.category_id")}
        </label>
        <select
          className="form-control custom-select"
          id="categoryId"
          name="category_id"
          value={formData.category_id}
          onChange={handleChange}
        >
          {categoryOptions()}
        </select>
      </div>

      <div className="form-group">
        <label
          htmlFor="segmentId"
          className="small"
        >
          {I18n.t("activerecord.attributes.lead.segment_id")}
        </label>
        <select
          className="form-control custom-select"
          id="segmentId"
          name="segment_id"
          value={formData.segment_id}
          onChange={handleChange}
        >
          {segmentOptions()}
        </select>
      </div>
    </>
  )
}

export default Fields;
