window.initiateTinyMCE = function () {
  tinymce.init({
    selector: '.tinymce-manage',
    height: 300,
    menubar: false,
    object_resizing: false,
    contextmenu: false,
    content_style: "table { width: 100% !important; } p, h1, h2, h3, h4, h5 { margin: 0px; }",
    branding: false,
    resize: false,
    statusbar: false,
    toolbar: 'undo redo | formatselect | bold italic | bullist numlist | image | link',
    paste_as_text: true,
    paste_data_images: true,
    plugins: ["table", "paste", "lists", "image", "link"],
    default_link_target: '_blank',
    images_upload_handler: function (blobInfo, success, failure) {
      var image_size = blobInfo.blob().size / 1000;
      var max_size_kbytes = 100
      if (image_size > max_size_kbytes) return failure('Image is too large( ' + image_size + ' kB), Maximum image size is:' + max_size_kbytes + ' kB', { remove: false });

      var reader = new FileReader();
      reader.readAsDataURL(blobInfo.blob());
      reader.onload = function () {
        success(this.result);
      }
    }
  });

  tinymce.init({
    selector: '.tinymce-supplier-profile',
    height: 300,
    menubar: false,
    object_resizing: false,
    contextmenu: false,
    content_style: "table { width: 100% !important; }",
    branding: false,
    resize: false,
    statusbar: false,
    toolbar: 'undo redo | bold italic | bullist numlist | link',
    paste_as_text: true,
    paste_data_images: false,
    plugins: ["table", "paste", "lists", "link"],
    default_link_target: '_blank',
  });
 
  tinymce.init({
    selector: '.tinymce-with-headings',
    min_height: 690,
    menubar: false,
    object_resizing: false,
    contextmenu: false,
    content_style: "table { width: 100% !important; }",
    branding: false,
    resize: true,
    statusbar: false,
    toolbar: 'undo redo | formatselect | bold italic | bullist numlist | link | save',
    block_formats: 'Paragraph=p;Heading 1=h1;Heading 2=h2;Heading 3=h3;Heading 4=h4;',
    paste_as_text: true,
    paste_data_images: false,
    plugins: ["table", "paste", "lists", "link"],
    default_link_target: '_blank',
  });

  let editorChangeHandler;

  tinymce.init({
    selector: '.tinymce-auto-save',
    height: 690,
    menubar: false,
    object_resizing: false,
    contextmenu: false,
    content_style: "table { width: 100% !important; }",
    branding: false,
    resize: false,
    statusbar: false,
    toolbar: 'undo redo | formatselect | bold italic | bullist numlist | link | save',
    block_formats: 'Paragraph=p;Heading 1=h1;Heading 2=h2;Heading 3=h3;Heading 4=h4;',
    paste_as_text: true,
    paste_data_images: false,
    plugins: ["table", "paste", "lists", "link"],
    default_link_target: '_blank',
    setup: function (editor) {
      editor.on('paste input change undo redo', function () {
        var editor = this;
        clearTimeout(editorChangeHandler);
        editorChangeHandler = setTimeout(function () {
          editor.formElement.submit();
        }, 1000);
      });
    }
  });

  tinymce.init({
    selector: '.tinymce-documents-editor',
    height: 700,
    menubar: false,
    object_resizing: false,
    contextmenu: false,
    content_style: "table { width: 100% !important; } .mce-content-body { padding: 40px; }",
    branding: false,
    resize: false,
    statusbar: false,
    toolbar: ['undo redo | formatselect fontsizeselect lineheight | bold italic underline strikethrough | forecolor backcolor | hr alignleft aligncenter alignright alignjustify', 'numlist bullist checklist outdent indent | table advcode'],
    block_formats: 'Paragraph=p;Heading 1=h1;Heading 2=h2;Heading 3=h3;Heading 4=h4;',
    paste_as_text: true,
    paste_data_images: false,
    plugins: ["table", "paste", "lists", "link"],
    default_link_target: '_blank',
  });
}

$(document).ready(function() {
  window.initiateTinyMCE();
});
