import React from "react"

const Block = ({ translationKey, className, children }) => {
  return (
    <div className={`row row-spaced-2 ${className}`}>
      <div className="col-12 col-sm-4">
        <small className="d-block">
          {I18n.t(`communication.event_list.${translationKey}`)}
        </small>
      </div>

      <div className="col-12 col-sm-8">
        {children}
      </div>
    </div>
  )
}

const ParticipantStatus = ({ status }) => {
  switch (status) {
    case "yes":
      return <i className="ph ph-check-circle ph-lg text-success" />
    case "no":
      return <i className="ph ph-x-circle ph-lg text-danger" />
    case "maybe":
      return <i className="ph ph-chat-circle-dots ph-lg text-warning" />
    case "noreply":
      return <i className="ph ph-question ph-lg text-muted" />
    default:
      return <></>
  }
}

const EventList = ({ events }) => {
  if (events.length == 0) return <small className="text-muted">{window.I18n.t("communication.event_list.no_events")}</small>

  return (
    <>
      {events.map((event, index) => (
        <div
          className="border p-3 mb-2"
          key={index}
        >
          <Block translationKey="title">
            <small
              className="d-block"
              title={event.title}
            >
              {event.title.substring(0, 30)}
            </small>
          </Block>

          <Block translationKey="time">
            <small
              className="d-block"
            >
              {event.when.startTime ? (
                <>
                  {moment.unix(event.when.startTime).format("dddd, MMMM D")} ⋅ {moment.unix(event.when.startTime).format("HH:mm")} - {moment.unix(event.when.endTime).format("HH:mm")}
                </>
              ) : (
                <>-</>
              )}
            </small>
          </Block>

          <Block translationKey="type">
            <small
              className="d-block"
            >
              {event.metadata.type}
            </small>
          </Block>

          <Block translationKey="channel">
            <small
              className="d-block"
            >
              {event.metadata.channel || "-"}
            </small>
          </Block>

          {event.metadata.videoUrl && (
            <Block translationKey="video">
              <a
                href={event.metadata.videoUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <small className="d-block mt-1">
                  {window.I18n.t("communication.event_list.video_link")}
                </small>
              </a>
            </Block>
          )}

          <Block translationKey="created_by">
            <small
              className="d-block"
            >
              {event.metadata.createdBy}
            </small>
          </Block>

          <Block translationKey="event_owner">
            <small
              className="d-block"
            >
              {event.owner}
            </small>
          </Block>

          <Block translationKey="confirmed">
            <small
              className="d-block"
            >
              {event.status == "confirmed" ? (
                <i className="ph ph-check ph-thin text-primary" />
              ) : (
                <i className="ph ph-x ph-thin text-danger" />
              )}
            </small>
          </Block>

          <Block
            translationKey="participants"
            className="mt-2"
          >
            <div className="d-flex flex-column gap-1">
              {event.participants.map((participant, index) => (
                <div
                  className="d-flex align-items-center gap-1"
                  key={index}
                >
                  <ParticipantStatus status={participant.status} />

                  <small className="d-block">
                    {[participant.name, participant.email, participant.phoneNumber].filter(Boolean).join(' - ')}
                  </small>
                </div>
              ))}
            </div>
          </Block>
        </div>
      ))}
    </>
  )
}

export default EventList
