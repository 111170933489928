document.addEventListener('DOMContentLoaded', function() {
  $('.lazy-load-notifications').each(function() {
    let lazyLoadContainer = $(this);
    let notificationsType = lazyLoadContainer.data('notificationsType') || null;

    lazyLoadNotifications(lazyLoadContainer, notificationsType);

    lazyLoadContainer.find('.notifications-container').scroll(function() {
      lazyLoadNotifications(lazyLoadContainer, notificationsType);
    });
  })
})

window.lazyLoadNotifications = function(dom, notificationsType = null) {
  let unreadNotificationsCounter = $(dom).find('.new-notifications');
  let notificationsContainer = $(dom).find('.notifications-container');

  let currPage = parseInt($(dom).data('currPage') || 0)
  let nextPage = currPage + 1

  let unreadNotificationsCount = 0;
  
  if(notificationsContainer.scrollTop() + notificationsContainer.innerHeight() >= notificationsContainer[0].scrollHeight) {
    $('.notification').attr('style', 'cursor: wait !important;')

    $.ajax({
      type: "GET",
      url: `/notifications?page=${nextPage}&notifications_type=${notificationsType}`
    }).done(function(data) {
      var html = $(data.html)

      // Display the number of unread notifications
      unreadNotificationsCount = notificationsContainer.find('.notification.notification-unread').length || html.find('.notification.notification-unread').length;

      if (unreadNotificationsCount == 0) {
        unreadNotificationsCounter.hide();
      } else {
        // 10 is the page size defined in NotificationsController
        let formattedUnreadNotificationsCount = unreadNotificationsCount >= 10 ? '10+' : unreadNotificationsCount;

        unreadNotificationsCounter.html(formattedUnreadNotificationsCount);
        unreadNotificationsCounter.show();
      }

      notificationsContainer.append(html)

      $('.notification').attr('style', '')
    });

    $(dom).data('currPage', nextPage)
  }
}
