import React, { useState, useEffect } from "react";

import { fetchRejectionCategories, rejectLead, updateLead } from './api';

const RejectModal = ({ lead, callback }) => {
  const [rejectionCategories, setRejectionCategories] = useState([]);
  const [formData, setFormData] = useState({
    rejection_category_id: "",
    rejection_comment: ""
  });

  const [rejectLeadLoading, setRejectLeadLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    fetchRejectionCategories()
      .then((response) => response.json())
      .then((data) => {
        setRejectionCategories(data);
      });
  }, []);

  const rejectionCategoryOptions = () => {
    const options = [];

    options.push(<option key="0" value="">{I18n.t("shared.select")}</option>);

    rejectionCategories.forEach((category) => {
      options.push(<option key={category.id} value={category.id}>{category.title}</option>);
    })

    return options;
  }

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setRejectLeadLoading(true);

    rejectLead({ leadId: lead.id, atrs: formData })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          setErrorMessage(data.error);
        } else {
          callback(data);
        }

        setRejectLeadLoading(false);
      });
  }

  return (
    <div className="modal fade" key="reject-lead-modal" id="reject-lead-modal">
      <div className="modal-dialog modal-lg modal-dialog-centered position-relative" role="document">
        <div className="modal-content modal-content-simple overflow-auto">
          <div className="modal-header px-4">
            <h5 className="modal-title" id="exampleModalLabel">{I18n.t("leads.reject_modal.title")}</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body p-4">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="rejection_category_id">{I18n.t("activerecord.attributes.lead.rejection_category_id")}</label>
                <select
                  className="form-control"
                  id="rejection_category_id"
                  name="rejection_category_id"
                  value={formData.rejection_category_id}
                  onChange={handleChange}
                >
                  {rejectionCategoryOptions()}
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="rejection_comment">{I18n.t("activerecord.attributes.lead.rejection_comment")}</label>
                <textarea
                  className="form-control"
                  id="rejection_comment"
                  name="rejection_comment"
                  value={formData.rejection_comment}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group d-flex justify-content-end">
                <button className="btn btn-primary" disabled={rejectLeadLoading}>
                  {rejectLeadLoading ? (
                    <span>
                      <div className="spin spin-xxxs spin-light mr-2" />
                      {I18n.t("shared.saving")}
                    </span>
                  ) : (
                    <>{I18n.t("shared.save")}</>
                  )}
                </button>
              </div>

              {errorMessage !== "" && (
                <div className="alert alert-danger">
                  {errorMessage}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RejectModal;
