import React from 'react';
import EventScheduler from '../EventScheduler';

const Reschedule = ({ event, callback }) =>{
  return (
    <>
      <h5 className='mb-1'>Reschedule</h5>
      <EventScheduler
        rescheduleEvent={event}
        participants={event.participants}
        callback={callback}
      />
    </>
  )
}

export default Reschedule
