export const fetchActReasons = () => {
  const url = "/act_reasons";

  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })
}

export const createAct = ({ type, subjectType, subjectId, actReasonId, description, dueAt, publicToSuppliers }) => {
  const url = "/acts";

  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      act: {
        type: type,
        subject_type: subjectType,
        subject_id: subjectId,
        act_reason_id: actReasonId,
        description: description,
        due_at: dueAt,
        public_to_suppliers: publicToSuppliers
      }
    })
  })
}

export const destroyAct = ({actId}) => {
  const url = `/acts/${actId}`;

  return fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })
}
