import React from "react"

import { pascalCaseToSnakeCase } from "../custom/helpers";

const actTypeDescription = (type) => {
  return window.I18n.t(`activerecord.attributes.act.types.${pascalCaseToSnakeCase(type.replace("Act::", ""))}`)
}

const ActSubjectBadge = ({ act }) => {
  const subject = act.subject

  let icon, content, path

  switch (act.subject_type) {
    case "Lead":
      icon = "ph-funnel"
      content = `Lead ${subject.id}`
      path = `/leads/${subject.id}`
      break
    case "User":
      icon = "ph-user"
      content = subject.name
      path = `/users/${subject.id}`
      break
    case "CaseFile":
      icon = "ph-archive"
      content = subject.title
      path = caseFilePath(subject.id)
      break
    default:
      icon = null
      content = "N/A"
      path = null
  }

  return (
    <small className="badge badge-blue-light font-weight-light">
      {path ? (
        <a
          href={path}
          className="d-flex align-items-center"
        >
          <i className={`ph ${icon} ph-thin mr-1`}></i>
          <span>{content}</span>
        </a>
      ) : (
        <div className="d-flex align-items-center">
          <i className={`ph ${icon} ph-thin mr-1`}></i>
          <span>{content}</span>
        </div>
      )}
    </small>
  )
}

const caseFilePath = (id) => {
  switch (window.currentAuth().user_type) {
    case "Admin":
      return `/manage/case_files/${id}`
    case "User":
      return `/case_files/${id}`
    case "Advisor":
      return `/supplier/case_files/${id}`
  }
}


const ListItem = ({ act, destroyAct }) => {
  const handleDestroyAct = ({ actId }) => {
    if (confirm(window.I18n.t("acts.list.confirm_delete"))) {
      destroyAct({ actId })
    }
  }

  return (
    <div className="log-entry">
      <div
        className="indicator"
        title={act.created_by?.name}
        style={{ backgroundImage: `url(${act.created_by?.avatar_url})` }} />

      <div className="description">
        <div className="title">
          <div className="mr-2">
            {window.I18n.t("leads.show.action", { action: actTypeDescription(act.type) })}
          </div>


        </div>

        <div className="timestamp">
          {moment(act.created_at).format("DD/MM/YYYY HH:mm")}
        </div>

        <div className="content">
          <small className={`${act.description ? "d-block mb-1" : ""}`}>
            {window.I18n.t("activerecord.attributes.act.act_reason")}: {act.act_reason?.title || "-"}
          </small>

          {act.description && (
            <>
              {window.I18n.t("activerecord.attributes.act.description")}: {act.description}
            </>
          )}
        </div>
      </div>

      <div className="actions">
        <button
          className="btn btn-link p-0"
          onClick={() => handleDestroyAct({ actId: act.id })}
          confirm={window.I18n.t("acts.list.confirm_delete")}
        >
          <i className="ph ph-trash ph-lg text-muted cursor-pointer"></i>
        </button>
      </div>
    </div>
  )
}

export default ListItem
