import React from "react";

import { updateLead } from "../api";

const AssignLead = ({ lead, setLead, setLeads }) => {
  const handleToggleAssign = () => {
    let userGid = window.currentAuth().user_gid;

    // If the lead already has an assignee, unassign it
    // Otherwise, assign it to the current user
    const assigneeGid = lead.assignee_gid ? null : userGid;

    updateLead({ leadId: lead.id, atrs: { assignee_gid: assigneeGid } })
      .then((response) => response.json())
      .then((data) => {
        setLead(data);
        setLeads((leads) => {
          return leads.map((_lead) => {
            if (_lead.id === data.id) {
              return data;
            } else {
              return _lead;
            }
          })
        })
      })
  }

  if (lead.assignee_gid) {
    return (
      <div className="d-flex align-items-center">
        <img
          src={lead.assignee.avatar_url}
          title={lead.assignee.name}
          className="shadow-sm rounded-circle mr-2"
          style={{width: "25px"}} />
        <small className="mr-2">
          {lead.assignee.name}
        </small>
        <small>
          <span
            className="cursor-pointer animated-last-arrow d-flex align-items-center"
            onClick={handleToggleAssign}
          >
            {lead.assignee_gid === window.currentAuth().user_gid ? I18n.t("leads.sidebar.unassign") : I18n.t("leads.sidebar.unassign_from", { name: lead.assignee.name })}
            <i className="ph ph-arrow-right ph-thin ml-1" />
          </span>
        </small>
      </div>
    )
  } else {
    return (
      <div className="d-flex align-items-center">
        <small className="text-danger mr-2">
          {I18n.t("leads.sidebar.unassigned")}
        </small>
        <small>
          <span
            className="cursor-pointer animated-last-arrow d-flex align-items-center"
            onClick={handleToggleAssign}
          >
            {I18n.t("leads.sidebar.assign")}
            <i className="ph ph-arrow-right ph-thin ml-1" />
          </span>
        </small>
      </div>
    )
  }
}

export default AssignLead;
