$(document).ready(function() {
  let commentsContainer = $('#document-comments-container')
  let newCommentContainer = $('.new-document-comment-container')
  
  if (commentsContainer.length) {
    $('#document-comments-container').animate({ scrollTop: $('#document-comments-container').prop('scrollHeight')}, 500)
  }

  if (newCommentContainer.length) {
    $('.resolve-document-comment').change(handleResolveDocumentComment)
    $('#document_comment_body').keyup(function() {
      window.onbeforeunload = function (e) {
        return true;
      };
    })
  }
})

window.handleSubmitDocumentComment = function() {
  if (!$('#document_comment_body').val().length) return;

  let documentAccessToken = $('#document_access_token').val()
  let authorType = $('#document_comment_author_type').length ? $('#document_comment_author_type').val() : null
  let authorId = $('#document_comment_author_id').length ? $('#document_comment_author_id').val() : null

  $.ajax({
    type: 'POST',
    url: `/api/v2/documents/${documentAccessToken}/document_comments`,
    headers: {
      Accept: 'application/json'
    },
    dataType: 'json',
    data: {
      document_comment: {
        body: `${$('#document_comment_body').val()}`,
        author_type: authorType,
        author_id: authorId
      }
    },
    statusCode: {
      201: function (response) {
        let commentEl = buildDocumentComment(response.data.attributes)
        $('#document-comments-container').append(commentEl)
        $('#document_comment_body').val('')
        enableButton()
        $('#document-comments-container').animate({ scrollTop: $('#document-comments-container').prop('scrollHeight')}, 500)
      },
      400: function () {
        CustomToastr.error(I18n.t('document_comments.create.error'))
        enableButton()
      },
      401: function () {
        CustomToastr.error(I18n.t('document_comments.create.unauthorized'))
        enableButton()
      }
    },
    beforeSend: function () {
      disableButton()
    }
  })
}

function buildDocumentComment(documentComment) {
  let commentEl = $(`
    <div class="comment-container">
      <div class="comment-wrapper author">
        <div class="comment-author">
          <small class="text-muted">${documentComment.author_name == null ? I18n.t('shared.document_comments.timeline.anonymous') : documentComment.author_name}</small>
          <small class="text-muted mr-2">${formatDateTime(documentComment.created_at)}</small>
        </div>
        <div class="comment">
          <div class="comment-body">
          </div>
        </div>
      </div>
    </div>
  `)

  commentEl.find('.comment-body').text(documentComment.body)

  return commentEl
}

function handleResolveDocumentComment() {
  let sender = $(this)
  let documentAccessToken = $('#document_access_token').val()
  let documentCommentId = $(this).data('document-comment-id')

  $.ajax({
    type: 'PUT',
    url: `/api/v2/documents/${documentAccessToken}/document_comments/${documentCommentId}/resolve`,
    headers: {
      Accept: 'application/json'
    },
    dataType: 'json',
    statusCode: {
      200: function (response) {
        let resolveEl = sender.parent().find('.resolve')
        let resolvedAt = response.data.attributes.resolved_at

        if (resolvedAt !== null) {
          resolveEl.find('.resolve-text').text(I18n.t('shared.document_comments.timeline.resolved'))
          resolveEl.find('.ph-check').removeClass('text-muted').addClass('text-success')
        } else {
          resolveEl.find('.resolve-text').text(I18n.t('shared.document_comments.timeline.resolve'))
          resolveEl.find('.ph-check').removeClass('text-success').addClass('text-muted')
        }
      },
      400: function () {
        CustomToastr.error(I18n.t('document_comments.resolve.error'))
      }
    }
  })
}

function formatDateTime(datetime) {
  return new Date(datetime).toLocaleString('da-DK', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  })
}

function disableButton() {
  $('#submit-document-comment').prop('disabled', true)
}

function enableButton() {
  $('#submit-document-comment').prop('disabled', false)
}
