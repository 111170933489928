const eventTypes = {
  'NeedsAnalysis': {
    color: '#7dae7b',
    translationKey: 'communication.event.fields.types.needs_analysis',
  },
  'ServiceMeeting':{
    color: '#e66940',
    translationKey: 'communication.event.fields.types.service_meeting',
  },
  'InternalMeeting': {
    color:'#4a9eea',
    translationKey: 'communication.event.fields.types.internal_meeting',
  },
  'Blocked': {
    color:'#f2bd2c',
    translationKey: 'communication.event.fields.types.blocked',
  },
  'Other': {
    color:'#e5677b',
    translationKey: 'communication.event.fields.types.other',
  }
}

export default eventTypes;
