window.confirmedPassword = function(...ids) {
  let values = []
  
  ids.forEach((id) => {
    values.push(document.querySelector(id).value)
  });

  return values.every((val) => val === values[0])
}

window.spin = function(text) {
  $('body').prepend(`
    <div class="loader-overlay">
      <div class="loader">&nbsp;</div>
      <div class="loader-explain">${text}</div>
    </div>
  `)
}

window.stopSpin = function() {
  $('.loader-overlay').remove()
}

export const pascalCaseToSnakeCase = (str) => { return str.split(/\.?(?=[A-Z])/).join('_').toLowerCase() }
