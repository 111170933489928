$(document).ready(function() {
  if ($('.scrollable-container').length && $('.scroll-btn').length) {
    $('.scroll-btn').click(handleClickNScroll);
    $('.scrollable-container').scroll(handleScroll);
  }
});

function handleClickNScroll() {
  let container = $($(this).data('target'));
  let direction = $(this).data('direction');
  if (direction === 'up') container.animate({ scrollTop: 0 }, 'slow');
  if (direction === 'down') container.animate({ scrollTop: container[0].scrollHeight }, 'slow');
  return false;
}

function handleScroll(e) {
  let container = $(e.currentTarget);

  if (container[0].scrollHeight - container.scrollTop() == container.outerHeight()) {
    // change button icon
    let button = $(`.scroll-btn[data-target='#${container.attr('id')}']`);
    button.children().first().removeClass('ph-arrow-circle-down').addClass('ph-arrow-circle-up');
    button.data('direction', 'up');
  } else if (container.scrollTop() === 0) {
    let button = $(`.scroll-btn[data-target='#${container.attr('id')}']`);
    button.children().first().removeClass('ph-arrow-circle-up').addClass('ph-arrow-circle-down');
    button.data('direction', 'down');
  }
}
