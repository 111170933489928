import React from "react"

const LeadState = ({ state, className }) => {
  return (
    <small className={`badge badge-lead-state lead-${state} ${className}`}>
      {window.I18n.t(`activerecord.attributes.lead.workflow_states.${state}`)}
    </small>
  )
}

export default LeadState
