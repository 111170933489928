import React from "react";

const ChannelField = ({ channel, setChannel }) => {
  const userContext = window.getSessionData();

  return (
    <div className="form-group">
      <label className="small">
        {window.I18n.t('communication.event.edit_event.channel')}
      </label>
      <select
        className="form-control form-control-sm h-auto"
        value={channel}
        onChange={(e) => setChannel(e.target.value)}
      >
        <>
          <option value="phone">{window.I18n.t("communication.event.fields.channel.phone")}</option>
          <option value="video">{window.I18n.t("communication.event.fields.channel.video")}</option>
          {(userContext.adminSignedIn || userContext.supplierSignedIn) && <option value="physical">{window.I18n.t("communication.event.fields.channel.physical")}</option>}
        </>
      </select>
    </div>
  )
}

export default ChannelField;
