import React from "react";

import eventTypes from '../../Helpers/EventTypes';

const TypeField = ({ type, setType }) => {
  return (
    <div className="form-group">
      <label className="small">
        {window.I18n.t('communication.event.edit_event.type')}
      </label>
      <select
        className="form-control form-control-sm h-auto"
        value={type}
        onChange={(e) => setType(e.target.value)}
      >
        {Object.entries(eventTypes).map(([key, eventType]) => (
          <option key={key} value={key}>{window.I18n.t(eventType.translationKey)}</option>
        ))}
      </select>
    </div>
  )
}

export default TypeField;
