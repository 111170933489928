import React from "react"

const LeadStatusColor = (status) => {
  let color = ""

  switch (status) {
    case "handled":
    case "passive":
      color = "success"
      break;
    case "upcoming":
      color = "warning"
      break;
    case "due":
      color = "danger"
      break;
    default:
      color = "success"
  }

  return color
}

const LeadStatus = ({ status, className }) => {
  return (
    <div className={`d-flex align-items-center ${className}`}>
      <small className={`badge badge-circle badge-circle-sm badge-${LeadStatusColor(status)}`}>
        <div className="text">
          <i className="ph ph-warning-circle"></i>
        </div>
      </small>
    </div>
  )
}

export default LeadStatus;
