import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from '@fullcalendar/interaction';

import TypeField from "./Event/Fields/TypeField";
import ChannelField from "./Event/Fields/ChannelField";
import locale from "./Helpers/FullCalendarLocales";
import calendarLoaderStyle from "./Helpers/CalendarLoaderStyle";
import { pascalCaseToSnakeCase } from "./Helpers/StringFormatters";

import './EventScheduler.scss'

const EventScheduler = ({
    leadId = null,
    participants = [],
    displayCalendarPlaceholder = false,
    disableCalendarSelection = false,
    disableMeetingLengthSelection = false,
    disableTypeSelection = false,
    disableChannelSelection = false,
    onlySeniorLawyers = false,
    rescheduleEvent = null,
    callback = null
  }) => {

  // Used for cleaning the state when unmounting the component
  const [state, setState] = useState({});

  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [categoriesLoading, setCategoriesLoading] = useState(true);

  const [calendarTokens, setCalendarTokens] = useState([]);
  const [calendarTokenId, setCalendarTokenId] = useState("");
  const [calendarTokensLoading, setCalendarTokensLoading] = useState(false);

  const [meetingLength, setMeetingLength] = useState(
    rescheduleEvent ?
      (Math.floor(rescheduleEvent.when.endTime - rescheduleEvent.when.startTime)/60).toString()
      : "30"
  );

  const [availableTimeslots, setAvailableTimeslots] = useState([]);
  const [calendarLoading, setCalendarLoading] = useState(false);

  const [dateRange, setDateRange] = useState({ start: "", end: "" });
  const [selectedDate, setSelectedDate] = useState(null);

  const [selectedTimeslot, setSelectedTimeslot] = useState({});

  // Event states
  const [type, setType] = useState("NeedsAnalysis");
  const [channel, setChannel] = useState("phone");

  // Booked event
  const [event, setEvent] = useState({});
  const [submitEventLoading, setSubmitEventLoading] = useState(false);

  // Errors
  const [errorMessage, setErrorMessage] = useState("");

  // Clean the state when unmounting the component
  useEffect(() => {
    return () => { setState({}) }
  }, [])

  useEffect(() => {
    // reset selected categoryId, selected calendarTokenId, availableTimeslots, selectedDate,
    // selectedTimeslot and previous booking when the component mounts
    setCategoryId("");
    setCalendarTokenId("");
    setAvailableTimeslots([]);
    setSelectedDate(null);
    setSelectedTimeslot({});
    setEvent({});

    setErrorMessage("");
  }, [leadId])

  useEffect(() => {
    setErrorMessage("");

    fetchCategories()
      .then((categories) => {
        setCategories(categories);
        setCategoriesLoading(false);
      })
  }, [])

  useEffect(() => {
    setCalendarTokensLoading(true);
    setErrorMessage("");

    if (categoryId === "") {
      setCalendarTokens([]);
      setCalendarTokenId("");
      setCalendarTokensLoading(false);
      return;
    }

    fetchCalendarTokens()
      .then((calendarTokens) => {
        setCalendarTokens(calendarTokens);
        setCalendarTokenId(disableCalendarSelection ? "all" : "");
        setCalendarTokensLoading(false);
      })
  }, [categoryId])

  useEffect(() => {
    if (dateRange.start === "" || dateRange.end === "" || calendarTokenId === "") return;

    setErrorMessage("");

    // Freeze the calendar when loading timeslots
    setCalendarLoading(true);

    // Fetch the available timeslots for startDate and endDate provided by FullCalendar (an entire month)
    fetchAvailableTimeslots()
      .then((data) => {
        setAvailableTimeslots(data);
        setCalendarLoading(false);
      })
  }, [dateRange, meetingLength, calendarTokenId, categoryId])

  const fetchCategories = () => {
    return fetch(`/communication/categories`)
      .then((response) => response.json())
  }

  const fetchCalendarTokens = () => {
    return fetch(`/communication/categories/${categoryId}/calendar_tokens?senior=${onlySeniorLawyers}`)
      .then((response) => response.json())
  }

  const fetchAvailableTimeslots = () => {
    const selectedCalendarTokenIds = calendarTokenId === "all" ? calendarTokens.map((calendarToken) => calendarToken.id) : [calendarTokenId];

    return fetch("/communication/calendar_tokens/availability", {
      method: "POST",
      body: JSON.stringify({
        calendar_token_ids: selectedCalendarTokenIds,
        from: dateRange.start,
        to: dateRange.end,
        meeting_length: meetingLength,
      }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content,
      }
    })
      .then((response) => response.json())
  }

  const categoryOptions = () => {
    let options = [<option value="" key={"none"}>{I18n.t("shared.select")}</option>];

    categories.sort((a, b) => a.title.localeCompare(b.title)).forEach((category) => {
      options.push(<option value={category.id} key={category.id}>{category.title}</option>);
    });

    return options;
  }

  const calendarTokenOptions = () => {
    let options = [<option value="" key={"none"}>{I18n.t("shared.select")}</option>];

    options.push(<option value="all" key={"all"}>{I18n.t("communication.event_scheduler.random")}</option>)

    calendarTokens.forEach((calendarToken) => {
      options.push(<option value={calendarToken.id} key={calendarToken.id}>{calendarToken.email}</option>);
    });

    return options;
  }

  // This function handles the dates set by the user in the calendar.
  // It is called when the user changes the view or changes the date range.
  const handleDatesSet = (info) => {
    setDateRange({
      start: moment(info.start).format("YYYY-MM-DD"),
      end: moment(info.end).format("YYYY-MM-DD"),
    });
  }

  const handleDateClick = (event) => {
    setSelectedDate(moment(event.dateStr).format("YYYY-MM-DD"));
    setSelectedTimeslot({})
  }

  const handleCreateEvent = () => {
    setErrorMessage("");
    setSubmitEventLoading(true);

    const startsAt = selectedTimeslot.startTime;
    const endsAt = selectedTimeslot.endTime;
    const calendarTokenEmails = selectedTimeslot.calendarTokenEmails;

    fetch(`/communication/events/create_for_random_calendar_token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content,
      },
      body: JSON.stringify({
        calendar_token_emails: calendarTokenEmails,
        event: {
          title: I18n.t(`communication.event.fields.types.${pascalCaseToSnakeCase(type)}`),
          startsAt: startsAt,
          endsAt: endsAt,
          participants: participants,
          video: channel === "video", // TODO: Remove this property once Björn has made sure that a video meeting will be created if channel == "video"
          channel: channel,
          source: "Core/Scheduler",
          type: type,
        },
        lead_id: leadId,
      })
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          setErrorMessage(data.error.message);
        } else {
          setEvent(data);
        }

        setSubmitEventLoading(false);

        if (callback) {
          callback(data);
        }
      })
  }

  const handleRescheduleEvent = () => {
    setErrorMessage('');
    setSubmitEventLoading(true);

    const startsAt = selectedTimeslot.startTime;
    const endsAt = selectedTimeslot.endTime;
    const calendarTokenEmails = selectedTimeslot.calendarTokenEmails;

    fetch(`/communication/events/${rescheduleEvent.id}/reschedule`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
      },
      body: JSON.stringify({
        calendar_token_emails: calendarTokenEmails,
        event: {
          startsAt: startsAt,
          endsAt: endsAt
        }
      })
    }).then((response) => response.json())
      .then((data) => {
        if (data.error) {
          setErrorMessage(data.error.message);
        } else {
          setEvent(data);
        }

        setSubmitEventLoading(false);

        if (callback) {
          callback(data);
        }
      });
  };

  const timeslotValue = (timeslot) => {
    return {
      startTime: timeslot.startTime,
      endTime: timeslot.endTime,
      calendarTokenEmails: timeslot.emails,
    }
  }

  const formattedTimeslot = (startTime, endTime) => {
    return `${moment.unix(startTime).format("DD-MM-YYYY")}: ${moment.unix(startTime).format("HH:mm")} - ${moment.unix(endTime).format("HH:mm")}`;
  }

  if (participants.length === 0) {
    return <small>{I18n.t("communication.event_scheduler.no_participants")}</small>
  }

  if (Object.keys(event).includes("id")) {
    return (
      <>
        <i className="ph ph-check-circle ph-3x text-success d-block text-center mb-1"></i>

        <p className="text-center mb-3">{I18n.t("communication.event_scheduler.booking_confirmation.title")}</p>

        <div className="d-flex justify-content-between mb-1">
          <small>{I18n.t("communication.event_scheduler.booking_confirmation.datetime_title")}</small>
          <small>{formattedTimeslot(event.when.startTime, event.when.endTime)}</small>
        </div>

        <div className="d-flex justify-content-between">
          <small>{I18n.t("communication.event_scheduler.booking_confirmation.lawyer_title")}</small>
          <small>{event.owner}</small>
        </div>
      </>
    )
  }

  return (
    <div className="event-scheduler">
      <div className="form-group">
        <label
          htmlFor="category"
          className="small"
        >
          {I18n.t("communication.event_scheduler.category")}
        </label>
        <select
          className="form-control"
          id="category"
          value={categoryId}
          onChange={(event) => setCategoryId(event.target.value)}
          disabled={categoriesLoading}
        >
          {categoryOptions()}
        </select>
      </div>

      { !disableCalendarSelection && calendarTokens.length > 0 && (
        <div className="form-group">
          <label
            htmlFor="calendar_token"
            className="small"
          >
            {I18n.t("communication.event_scheduler.calendar")}
          </label>
          <select
            className="form-control"
            id="calendar_token"
            value={calendarTokenId}
            onChange={(event) => setCalendarTokenId(event.target.value)}
            disabled={calendarTokensLoading}
          >
            {calendarTokenOptions()}
          </select>
        </div>
      )}

      { !rescheduleEvent && !disableMeetingLengthSelection && calendarTokenId !== "" && (
        <div className="form-group">
          <label
            htmlFor="meeting_length"
            className="small"
          >
            {I18n.t("communication.event_scheduler.meeting_length")}
          </label>
          <select
            className="form-control"
            id="meeting_length"
            value={meetingLength}
            onChange={(event) => setMeetingLength(event.target.value)}
          >
            <option value={30} key={30}> 30 </option>
            <option value={60} key={60}> 60 </option>
            <option value={90} key={90}> 90 </option>
          </select>
        </div>
      )}

      { (displayCalendarPlaceholder || calendarTokenId !== "") && (
        <div className="form-group" style={calendarLoading ? calendarLoaderStyle : {}}>
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            views={{
              dayGridMonth: {
                type: "dayGridMonth",
                buttonText: window.I18n.t("communication.calendar_app.month_view"),
                weekNumbers: false,
                showNonCurrentDates: false,
                dayCellClassNames: function(info) {
                  const dateStr = moment(info.date).format("YYYY-MM-DD");

                  // Disable day if it has no available timeslots
                  if(!(dateStr in availableTimeslots)) {
                    return 'fc-day-disabled';
                  }

                  return 'day-selectable';
                },
              }
            }}
            locale={locale()}
            selectable={calendarTokenId !== ""}
            datesSet={handleDatesSet}
            dateClick={handleDateClick}
          />
        </div>
      )}

      { calendarTokenId !== "" && selectedDate && (
        <div className="form-group">
          { !Object.keys(availableTimeslots).includes(selectedDate) && (
            <small>{I18n.t("communication.event_scheduler.no_available_dates")}</small>
          )}

          { Object.keys(availableTimeslots).includes(selectedDate) && Object.keys(availableTimeslots[selectedDate]).length > 0 && (
            <>
              <div className="d-flex gap-1 flex-wrap">
                {Object.keys(availableTimeslots[selectedDate]).map((key, index) => (
                  <label
                    className="radiocard-label"
                    key={index}
                  >
                    <input
                      type="radio"
                      name="timeslot"
                      checked={selectedTimeslot?.startTime === availableTimeslots[selectedDate][key].startTime}
                      onChange={() => setSelectedTimeslot(timeslotValue(availableTimeslots[selectedDate][key]))}
                    />
                    <div className="radiocard-icon with-dot d-flex text-left">
                      <div className="font-weight-light small">{moment.unix(availableTimeslots[selectedDate][key].startTime).format("HH:mm")}</div>
                    </div>
                  </label>
                ))}
              </div>
            </>
          )}
        </div>
      )}

      { !rescheduleEvent && Object.keys(selectedTimeslot).length > 0 && (
        <>
          {!disableTypeSelection && <TypeField type={type} setType={setType} />}
          {!disableChannelSelection && <ChannelField channel={channel} setChannel={setChannel} />}

        </>
      )}
      { Object.keys(selectedTimeslot).length > 0 && (
        <>
          <div className="form-group">
            <small>{I18n.t("communication.event_scheduler.prebooking_confirmation", { datetime: formattedTimeslot(selectedTimeslot.startTime, selectedTimeslot.endTime) })}</small>
          </div>

          <div className='form-group'>
            <button
              className='btn btn-primary btn-block'
              onClick={rescheduleEvent ? handleRescheduleEvent : handleCreateEvent}
              disabled={submitEventLoading}
            >
              {I18n.t(rescheduleEvent ?
                'communication.event_scheduler.reschedule_event' :
                'communication.event_scheduler.create_event')
              }
            </button>
          </div>

          { errorMessage !== "" && (
            <div className="alert alert-danger">
              {errorMessage}
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default EventScheduler;

// Custom event is triggered in app/views/leads/_sidebar.slim when the sidebar is toggled.
// This triggers the rendering of the EventScheduler component.
document.addEventListener("LeadsSidebarLoaded", (event) => {
  const leadId = event.detail?.leadId;
  const participants = event.detail?.participants || [];

  if (!leadId) return;

  const domEl = document.querySelector(".event-scheduler-container");

  if (!domEl) return;

  ReactDOM.render(
    <EventScheduler leadId={leadId} participants={participants} />,
    domEl
  );
})

// When the page is loaded, render the EventScheduler component if the container is present.
// This is used in app/views/users/show.html.slim.
document.addEventListener("DOMContentLoaded", () => {
  const domEl = document.querySelector(".event-scheduler-container");

  if (!domEl) return;

  const leadId = domEl.dataset.leadId || null;
  const participants = JSON.parse(domEl.dataset.participants) || [];
  const displayCalendarPlaceholder = domEl.dataset.displayCalendarPlaceholder === "true";
  const disableCalendarSelection = domEl.dataset.disableCalendarSelection === "true";
  const disableMeetingLengthSelection = domEl.dataset.disableMeetingLengthSelection === "true";
  const disableTypeSelection = domEl.dataset.disableTypeSelection === "true";
  const disableChannelSelection = domEl.dataset.disableChannelSelection === "true";
  const onlySeniorLawyers = domEl.dataset.onlySeniorLawyers === "true";

  ReactDOM.render(
    <EventScheduler
      leadId={leadId}
      participants={participants}
      displayCalendarPlaceholder={displayCalendarPlaceholder}
      disableCalendarSelection={disableCalendarSelection}
      disableMeetingLengthSelection={disableMeetingLengthSelection}
      disableTypeSelection={disableTypeSelection}
      disableChannelSelection={disableChannelSelection}
      onlySeniorLawyers={onlySeniorLawyers} />,
    domEl
  );
})
