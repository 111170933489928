import React, { useState } from 'react';
import { useNylas } from '@nylas/nylas-react';

const NylasLogin = () => {
  const nylas = useNylas();
  const [email, setEmail] = useState('');

  const loginUser = (e) => {
    e.preventDefault();

    sessionStorage.setItem('userEmail', email);

    nylas.authWithRedirect({
      emailAddress: email,
      successRedirectUrl: '',
    });
  };

  return (
    <div className="row row-spaced-2">
      <p className="col-12">{window.I18n.t('communication.nylas_login.connect_description')}</p>
      <div className="col-12 col-xl-6">
        <form
          onSubmit={loginUser}
        >
          <div className="form-group">
            <label htmlFor="email">{window.I18n.t('activerecord.attributes.user.email')}</label>
            <div className="input-group">
              <input
                required
                id="email"
                type="email"
                value={email}
                className="form-control"
                onChange={(e) => setEmail(e.target.value)}
              />
              <div className="input-group-append">
                <button
                  type="submit"
                  className="btn btn-primary">{window.I18n.t('shared.connect')}</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default NylasLogin;
