document.addEventListener('DOMContentLoaded', () => {
  
  $('.insert-variable').on('click', function() {
    var variableText = $(this).data('insertVal');
    var editorSelector = $(this).data('editorSelector');
    tinymce.get(editorSelector).execCommand('mceInsertContent', true, variableText);
  });




  
  // FIXME: Refactor this entire file
  if ($('.document_part_form').length) {
    hideContextFields();
    $('#document_part_part_type').change(handleContextFields);
    $('#document_part_part_type').trigger('change');
    $('.part-answer-answer').keyup(handlePartAnswerAnswer);
    $('#new-part-answer').click(handleAddPartAnswer);
    $('.remove-part-answer').click(handleRemovePartAnswer);

    let form = $('.document_part_form');
    let submitBtn = form.find('input[type=submit]');

    let outputContainer = document.querySelector('#output-container')
    let quillOutput = Quill.find(outputContainer)

    // $('.insert-variable').click(function () {
    //   var text = $(this).data('insertVal');
    //   var selection = quillOutput.getSelection(true);
    //   quillOutput.insertText(selection.index, text);
    // })

    submitBtn.click(function (e) {
      e.preventDefault();
      $('.context-field-container.d-none > input, .context-field-container.d-none > .radio > input:checked, .context-field-container.d-none > textarea, .context-field-container.d-none > select').each(function () {
        $(this).val('');
        $(this).prop('checked', false)
      })

      // FIXME: Refactor
      if (['parties', 'static_text_block', 'roads', 'company_search', 'information'].includes($('#document_part_part_type').val())) {
        form.submit();
      } else if ($('#document_part_part_type').val() == 'list' && ['', '[]'].includes($('#document_part_list_placeholders').val())) {
        CustomToastr.error(`You can't have a list part without placeholders!`);
      } else if (quillOutput.root.innerHTML.includes('%{answer}')) {
        form.submit();
      } else {
        CustomToastr.error('You need to insert the %{answer} variable in the output!');
        form.find('.insert-part-answer').addClass('badge-danger');
        setTimeout(function () {
          form.find('.insert-part-answer').removeClass('badge-danger');
        }, 5000)
      }
    });
  }
});

function updateOrdering(sections) {
  Object.keys(sections).map((key, index) => {
    let id = sections[key].id;
    let title = sections[key].title;
    let order = sections[key].order;
    $(`#section-${id}`).find('button').first().text(`${order}. ${title}`);
  });
};

function handleContextFields() {
  hideHelpTextContainer();

  switch ($(this).val()) {
    case 'number_input':
      hideContextFields();
      showFields('internal_title', 'question', 'subheader', 'output', 'collects', 'unit');
      showHelpTextContainer();
      break;
    case 'number_slider':
      hideContextFields();
      showFields('internal_title', 'question', 'subheader', 'output', 'collects', 'min', 'max', 'step', 'unit');
      showHelpTextContainer();
      break;
    case 'parties':
      hideContextFields();
      showFields('internal_title', 'question', 'subheader', 'output', 'collects', 'parties', 'optional_parties', 'extended_party_info', 'min', 'max', 'party_types');
      showHelpTextContainer();
      break;
    case 'static_text_block':
      hideContextFields();
      showFields('internal_title', 'output');
      break;
    case 'information':
      hideContextFields();
      showFields('internal_title', 'question', 'subheader', 'help_text', 'output');
      showQuillImageToolbar();
      break;
    case 'checkboxes':
      hideContextFields();
      showFields('internal_title', 'question', 'subheader', 'output', 'collects', 'checkbox_format');
      showHelpTextContainer();
      showPartAnswers();
      // TODO: Remove target
      hidePartAnswersFields();
      showPartAnswerFields('answer', 'help_text', 'output', 'order');
      break;
    case 'radio_buttons':
      hideContextFields();
      showFields('internal_title', 'question', 'subheader', 'output', 'collects');
      showHelpTextContainer();
      showDisplayFormat();
      showPartAnswers();
      // TODO: Show target
      hidePartAnswersFields();
      showPartAnswerFields('answer', 'help_text', 'output', 'target_id', 'order');
      break;
    case 'roads':
      hideContextFields();
      showFields('internal_title', 'question', 'subheader', 'collects');
      showHelpTextContainer();
      showDisplayFormat();
      showPartAnswers();
      hidePartAnswersFields();
      showPartAnswerFields('answer', 'help_text', 'target_id', 'order');
      break;
    case 'list':
      hideContextFields();
      showFields('internal_title', 'question', 'subheader', 'output', 'collects', 'list_placeholders', 'list_type', 'min', 'max');
      showHelpTextContainer();
      break;
    case 'interval':
      hideContextFields();
      showFields('internal_title', 'question', 'subheader', 'output', 'collects', 'interval_separator', 'interval_type');
      showHelpTextContainer();
      break;
    default:
      hideContextFields();
      showFields('internal_title', 'question', 'subheader', 'output', 'collects');
      showHelpTextContainer();
      break;
  }
}

function handlePartAnswerAnswer() {
  let val = $(this).val() || 'MISSING ANSWER';
  $(this).parent().parent().parent().parent().find('h5 > button').html(val);
}

function hideQuillImageToolbar() {
  $('.ql-image').parent().hide()
}

function showQuillImageToolbar() {
  $('.ql-image').parent().show()
}

function handleAddPartAnswer() {
  let answerContainerEls = $('.part-answer-container');
  let lastAnswerContainerEl = answerContainerEls.last();
  let lastAnswerContainerId = parseInt((lastAnswerContainerEl.attr('id') || 'part-answer-0').replace('part-answer-', ''));
  let nextId = lastAnswerContainerId + 1;
  let html = buildPartAnswer(nextId);
  let targets = $('.part-answer-target').first().html();
  $('#part-answers-container').append(html);
  $('.part-answer-target').last().html(targets);
  $('.part-answer-answer').keyup(handlePartAnswerAnswer);
  $('.remove-part-answer').click(handleRemovePartAnswer);

  if ($('#document_part_part_type').val() == 'roads') {
    hidePartAnswersFields();
    showPartAnswerFields('answer', 'help_text', 'target_id', 'order');
  }

  if ($('#document_part_part_type').val() == 'checkboxes') {
    hidePartAnswersFields();
    showPartAnswerFields('answer', 'help_text', 'output', 'order');
  }
}

function handleRemovePartAnswer() {
  $($(this).data('target')).remove();
}

function showHelpTextContainer() {
  $('#help-text-container').parent().removeClass('d-none');
}

function hideHelpTextContainer() {
  $('#help-text-container').parent().addClass('d-none');
}

function hideContextFields() {
  $('.context-field-container').addClass('d-none');
  hidePartAnswers();
  hideQuillImageToolbar();
}

function showFields(...fields) {
  fields.forEach(field => {
    $(`#document_part_${field}`).parent().removeClass('d-none');
  });

  fields.forEach(field => {
    $(`#document_part_${field}`).parent().parent().removeClass('d-none');
  });
}

function hidePartAnswersFields() {
  $('#part-answers').find('.form-group').addClass('d-none');
}

function showPartAnswerFields(...fields) {
  fields.forEach(field => {
    $(`[id$=_${field}]`).parent().removeClass('d-none');
  });
}

function showPartAnswers() {
  $('#part-answers').removeClass('d-none');
}

function showDisplayFormat() {
  $('#display-format-container').removeClass('d-none');
}

function hidePartAnswers() {
  $('#part-answers').addClass('d-none');
}

function buildPartAnswer(id) {
  return $.parseHTML(`
    <div class="card part-answer-container col-sm-6 col-lg-4 p-0" id="part-answer-${id}">
      <div class="p-3 d-flex justify-content-between">
        <h5 class="mb-0">
          <button aria-controls="part-answer-collapse-${id}" aria-expanded="true" class="btn btn-link btn-block text-left p-0" data-target="#part-answer-collapse-${id}" data-toggle="collapse" type="button">MISSING PART ANSWER</button>
        </h5>
        <button class="btn btn-link p-0 remove-part-answer" type="button" data-target="#part-answer-${id}">
          <i class="ph ph-trash ph-thin mr-2"></i>Delete
        </button>
      </div>

      <div class="collapse show" data-parent="#part-answers-container" id="part-answer-collapse-${id}">
        <div class="card-body">
          <div class="form-group">
            <label for="document_part_document_part_answers_attributes_${id}_answer">Answer</label>
            <input class="form-control part-answer-answer"
                  placeholder="Answer"
                  type="text"
                  name="document_part[document_part_answers_attributes][${id}][answer]"
                  id="document_part_document_part_answers_attributes_${id}_answer">
          </div>

          <div class="form-group">
            <label for="document_part_document_part_answers_attributes_${id}_help_text">Help text</label>
            <textarea class="form-control"
                      placeholder="Help text"
                      rows="5"
                      name="document_part[document_part_answers_attributes][${id}][help_text]"
                      id="document_part_document_part_answers_attributes_${id}_help_text"></textarea>
          </div>

          <div class="form-group">
            <label for="document_part_document_part_answers_attributes_${id}_output">Output</label>
            <textarea class="form-control"
                      placeholder="Output"
                      rows="5"
                      name="document_part[document_part_answers_attributes][${id}][output]"
                      id="document_part_document_part_answers_attributes_${id}_output"></textarea>
          </div>

          <div class="form-group">
            <label for="document_part_document_part_answers_attributes_${id}_target">Target</label>
            <select class="form-control part-answer-target"
                    name="document_part[document_part_answers_attributes][${id}][target_id]"
                    id="document_part_document_part_answers_attributes_${id}_target_id"></select>
          </div>

          <div class="form-group">
            <label for="document_part_document_part_answers_attributes_${id}_order">Order</label>
            <input class="form-control"
                   placeholder="Order"
                   type="number"
                   value="1"
                   name="document_part[document_part_answers_attributes][${id}][order]"
                   id="document_part_document_part_answers_attributes_${id}_order">
          </div>
        </div>
      </div>
    </div>
  `);
}

document.addEventListener("keydown", function (e) {
  if (e.keyCode == 83 && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) {
    e.preventDefault();
    $('.document_part_form').submit();
  }
}, false);
