window.formatGooglePlace = function(place) {
  var formatted_place = place.address_components.reduce((seed, { long_name, short_name, types }) => {
    types.forEach(t => {
      seed[t] = { long_name, short_name }
    });

    return seed;
  }, {});

  let country_code = formatted_place?.country?.short_name;
  let state_code = formatted_place?.administrative_area_level_1?.short_name;
  let zip_code = formatted_place?.postal_code?.long_name;
  let city = formatted_place?.locality?.long_name || formatted_place?.administrative_area_level_2?.long_name;
  let neighbourhood = formatted_place?.sublocality?.long_name || formatted_place?.sublocality_level_1?.long_name;
  let street_name = formatted_place?.route?.long_name;
  let street_number = formatted_place?.street_number?.long_name || formatted_place?.street_number;
  let address = `${street_name || ''} ${street_number || ''}`.trim();
  let apartment = formatted_place?.apartment;
  let country = formatted_place?.country?.long_name;

  return {
    country_code,
    state_code,
    zip_code,
    city,
    neighbourhood,
    street_name,
    street_number,
    address,
    apartment,
    country
  }
}
