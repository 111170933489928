import React, { useState, useEffect } from "react";
import Fields from "./Fields";

const NewEvent = ({ _startsAt, _endsAt, _leadId, setEvents, calendarToken }) => {
  const [title, setTitle] = useState("");
  const [startsAt, setStartsAt] = useState(_startsAt);
  const [endsAt, setEndsAt] = useState(_endsAt);
  const [leadId, setLeadId] = useState(_leadId);
  const [participants, setParticipants] = useState([]);
  const [newParticipant, setNewParticipant] = useState("");
  const [type, setType] = useState("Other");
  const [channel, setChannel] = useState("phone");

  const [createEventLoading, setCreateEventLoading] = useState(false);

  // We fetch the lead and store it in here
  const [lead, setLead] = useState({});

  useEffect(() => {
    setStartsAt(_startsAt || moment().seconds(0).unix());
    setEndsAt(_endsAt || moment().seconds(0).unix());
    setLeadId(_leadId);
  }, [_startsAt, _endsAt, _leadId])

  // We fetch the lead if the leadId is set
  useEffect(() => {
    if (leadId) {
      fetch(`/leads/${leadId}`, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          setLead(data);
          setParticipants([data?.customer_info?.email]);
        })
    }
  }, [leadId])

  const handleAddParticipant = () => {
    setParticipants([...participants, newParticipant]);
    setNewParticipant("");
  }

  const handleCreateEvent = () => {
    setCreateEventLoading(true);

    fetch(`/communication/calendar_tokens/${calendarToken.id}/events`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content,
      },
      body: JSON.stringify({
        event: {
          title: title,
          startsAt: startsAt,
          endsAt: endsAt,
          participants: participants,
          video: channel == "video", // TODO: Remove this property once Björn has made sure that a video meeting will be created if channel == "video"
          channel: channel,
          source: "Core",
          type: type,
        },
        lead_id: lead?.id,
      })
    })
      .then((response) => response.json())
      .then((data) => {
        setEvents((events) => [...events, data]);

        setCreateEventLoading(false);

        // Close the modal
        document.querySelector(".modal.fade.show").click();

        // Show a flash message
        window.CustomToastr.success(window.I18n.t("communication.event.created"));

        // Remove toggle_new_event_modal and lead_id from UrlSearchParams
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.delete("toggle_new_event_modal");
        urlParams.delete("lead_id");
        setLead({});
      })
  }

  return (
    <>
      {Object.keys(lead).length > 0 && (
        <div className="alert alert-primary">
          <small>{window.I18n.t("communication.event.new_modal.event_for_lead")}</small>
          <small><b>: {lead?.customer_info?.name}</b></small>

          <div className="d-block mt-1">
            <a href={`/leads/?lead_id=${leadId}`} className="btn btn-sm btn-primary">
              <small>{window.I18n.t("communication.event.new_modal.view_lead")}</small>
            </a>
          </div>
        </div>
      )}

      <Fields
        title={title}
        setTitle={setTitle}
        startsAt={startsAt}
        setStartsAt={setStartsAt}
        endsAt={endsAt}
        setEndsAt={setEndsAt}
        participants={participants}
        setParticipants={setParticipants}
        newParticipant={newParticipant}
        setNewParticipant={setNewParticipant}
        handleAddParticipant={handleAddParticipant}
        type={type}
        setType={setType}
        channel={channel}
        setChannel={setChannel}
      />

      <div className="form-group mb-0">
        <button
          className="btn btn-primary btn-block btn-sm"
          onClick={() => handleCreateEvent()}
          disabled={createEventLoading || startsAt > endsAt}
        >
          {window.I18n.t('shared.save')}
        </button>
      </div>
    </>
  )
}

export default NewEvent;
